/* eslint-disable */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const TsAndCs = () => {
	return (
		<div className="bg-white terms-conditions-page fixed m-auto inset-0 p-8 overflow-scroll" style={{ zIndex: 9999 }}>
			<Link className="text-blue-500 text-xl flex" to="/">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
					/>
				</svg>
				Back
			</Link>
			<ReactMarkdown>
				{`**TERMS AND CONDITIONS FOR TOYOTA TOUCH CAMPAIGN AND**

**WOVEN CITY JOURNEY**

**1. Definitions:**

1. The Promoter

Toyota South Africa Motors Pty (Ltd).

Stand 1, Wesco Park, Old Pretoria Road, Sandton. P O Box 481, Bergvlei, 2012.

VAT no: 4480103169. Registration no: 1961/001767/07

1. Toyota Touch Season 9 Campaign &amp; Woven City Learning Path

Refers to the name given to an internal Toyota Values campaign aimed at bringing the Toyota Values, Philosophy and Way of Life for brand ambassadors in an inspiring way while connecting them to the values, brand, and their teams on a personal level.

1. Administration of the Campaign

The designated representatives from Mann Made Media and the Promoter acting in accordance with their duties as agreed upon by both Mann Made and the Promoter.

1. Toyota SA Motors Locations

The campaign will be based on a digital platform with content shared per phase (3 phases) , with Brand Ambassadors working through the Learning Path journey that leads to the Woven City:

1. Download and Register for the App
2. Activate the App and Login
3. Access the campaign content

e) Brand Ambassadors

1. Refers to all Toyota Staff Members whom Toyota aims to connect with through the Toyota Touch Campaign. The campaign aims to instill the value of Toyota which the Brand Ambassadors need to display not only at work but also in their day to day living.

**2. Campaign Description:**

1. The Toyota Touch Campaign (hereinafter referred to as the Campaign) will run from March 2022 to November 2022. (prior communication i.e campaign teaser to go live Dec 2021, before the campaign launches in March) .
2. For the duration stated above, the Campaign will be live on a digital platform ( eToyota App), the campaign uses a 3 Phase approach, with an initial gifting through desk drops. In each Phase the Brand Ambassadors get to learn and connect with the Toyota Values and Philosophy deeper, through consuming content, interacting with different quizzes, partaking in dance challenges, posting content on the wall of happiness, participants unlock new icons as they finish modules in the learning path, and this will later allow them access to the Woven city to interact with different elements that make the city what it is and the selected participants can compete for the cash prize.
3. The learning path is designed as a platform to interact with Brand Ambassadors in a simple and easy way to use, the system is point based with points collected through each completed module, it is a linear approach making it easy to track one&#39;s progress. Content will be shared/ updated per phase and brand ambassadors must view the content individually to progress while interacting with focused team interactions .

**3. Privacy to Brand Ambassadors information:**

1. Prize competition is open to permanent salaried staff members, permanent contractors and TFS staff of Toyota SA Motors, Atlas and Sandton branches only. (Dealership employees)
2. Prize competition is strictly internal, running from May 2021 until November 2021.
3. The Prize competition winner will be drawn once, after assessing the leaderboard.Winner on the leaderboard will be selected immediately during the course of the Season 9 show in that Dealership. The judges decision is final.
4. The Prize Entries and Prize winner selection will happen as follows:

1. Competitors can play during a finite time frame and will gain points through answering questions correctly. Each category will have a set of 5 questions for one to complete.

**4.**  **Web-App - User Journey** - _ **For this section please refer to the below:** _

1. **First time User: if you have e-Toyota**
1. User scans QR Code (sent via email) or visits [**toyotatouch.co.za**](http://toyotatouch.co.za/)_(login gateway)_
2. User selects Login with e-Toyota
3. User logs in successfully filling in:
1. Username
2. Password
4. e-Toyota token generated
5. Link opens to the _Check Point_ to [**toyotatouch.co.za**](http://toyotatouch.co.za/)
6. Any missing employee data retrieved from Database via API
7. e-Toyota token found
8. Woven City token generated
9. [**toyotatouch.co.za**](http://toyotatouch.co.za/) Data warning and Ts and Cs
1. Any missing employee data retrieved from Database via API
10. User agrees to Ts and Cs
11. [**toyotatouch.co.za**](http://toyotatouch.co.za/) Data warning and Ts and Cs
12. Loading screen appears
13. Woven City Introduction slides
14. Home Screen appears

1. **First time User: if you** _ **do no** _ **t have eToyota and have a VALID Dealer Code:**
1. User scans QR Code or visits [**toyotatouch.co.za**](http://toyotatouch.co.za/)_(login gateway)_
2. e-Toyota Token Not Found?
3. User is redirected to a Form
4. User registration with valid dealer code:
1. First Name
2. Surname
3. ID/passport
4. Dealer code
5. User selects register
6. Woven City token generated
7. [**toyotatouch.co.za**](http://toyotatouch.co.za/) Data warning and Ts and Cs
1. Any missing employee data retrieved from Database via API
8. User agrees to Ts and Cs
9. [**toyotatouch.co.za**](http://toyotatouch.co.za/) Data warning and Ts and Cs
10. Loading screen appears
11. Woven City Introduction slides
12. Home Screen appears
2. **First time User: if you** _ **do no** _ **t have eToyota and no VALID Dealer Code:**
1. User scans QR Code or visits [**toyotatouch.co.za**](http://toyotatouch.co.za/)_(login gateway)_
2. e-Toyota Token Not Found?
3. User is redirected to a Form, at the bottom advises:
1. &#39;Don&#39;t know your dealer code? Please ask your Manager&#39;
4. The User then contacts their Manager, who will provide a Dealer Code for the User
5. Once received, the User can then scan QR Code or visits [**toyotatouch.co.za**](http://toyotatouch.co.za/)_(login gateway)_
6. e-Toyota Token Not Found?
7. User is redirected to a Form
8. User registration with valid code from Manager:
1. First Name
2. Surname
3. ID/passport
4. Dealer code
9. User selects register
10. Woven City token generated
11. [**toyotatouch.co.za**](http://toyotatouch.co.za/) Data warning and Ts and Cs
1. Any missing employee data retrieved from Database via API
12. User agrees to Ts and Cs
13. [**toyotatouch.co.za**](http://toyotatouch.co.za/) Data warning and Ts and Cs
14. Loading screen appears
15. Woven City Introduction slides
16. Home Screen appears

1. **Woven City Info Slides show:**
1. Imagine the City of the future;
2. Introducing Toyota&#39;s Woven City;
3. What&#39;s it all about?;
4. How do I get there?;
5. Win your share of R30 000 in cash prizes;
6. Ready to start your journey?;

1. **Home Screen Overlay: (Go Button)**
1. User will start a tutorial prompt, requiring them to &#39; Tap the button to start&#39;
2. User is able to select the Go Button leading them to the **journey;**
3. The Go Button currently shows:
1. Week;
2. Level and Value;
3. Scoring Percentage;

1. **Once the User has selected &#39;Go&#39; , the journey automatically opens with &#39; Know the Basics&#39;.**

1. **Know the Basics:**

1. This section has &#39;swipe to continue&#39; prompts;
2. Intro to featured value;
3. Value statement;
4. Unpacked with examples;
5. Complete with a Pop Quiz;
6. The User is automatically taken back to the Home Screen;

1. **Route Update:**

1. Once the User has completed &#39;Know the Basics&#39;, they are then taken through to the &#39;Home Screen&#39; and what it offers:

1. **Home Screen will show:**

1. The actual learning path and journey, which will be greyed out, excluding the previous and current modules;
2. Know the Basics will now be in colour;
3. Users profile and number of points - in the top right corner;
4. A Toggle Main Map View;
5. Bottom Navigation, which includes the following icons:
1. Leaderboards;
2. Wall of Happiness;
3. The Journey;
4. Values VoiceNotes;
5. Info (including Values Vault)
6. Second module &#39; Quick Quiz&#39; will now be flashing (animated) in colour;
7. The User can select the &#39;Go Button&#39; to start the new module;
1. Button showing: Level | Value | Current Score Percentage

1. **Quick Quiz:**

1. Intro to Quiz with Questions that are automated with no prompt;
2. If an answer is not selected, the user is prompted to &#39; please select an answer to continue&#39;
1. Question 1 (with 3 x Answer options);
2. Question 2 (with 3 x Answer options);
3. Questions 3 (with 3 x Answer options);
4. Result! with a &#39;swipe up to Finish&#39; prompt;

1. **Route Update - Home Screen:**

1. Once the User has completed &#39;Quick Quiz&#39;, the new module is introduced in colour and will be flashing (animated);
2. The User will be able to see their points in the top right corner, as well;
3. The User can select the &#39;Go Button&#39; to start the new module;
1. Button showing: Level | Value | Current Score Percentage

1. **Values View:**

1. Intro to Values View;
2. Full screen video to be played by the User;
3. Question (with 3 x Answer options);
4. The User is automatically taken back to the Home Screen;

1. **Route Update - Home Screen:**

1. Once the User has completed &#39;Values View&#39;, the new module is introduced in colour and will be flashing (animated);
2. The User will be able to see their points in the top right corner, as well;
3. The User can select the &#39;Go Button&#39; to start the new module;
1. Button showing: Level | Value | Current Score Percentage;

1. **Wall of Happiness:**

1. Intro to Wall of Happiness;
2. Create Tool;
1. The User then has a text and picture option for submission;
3. Submit;
1. Once submitted, user image is then sent to a dashboard for approval;
2. Then added to the main wall;
4. Woven City loading screen;
5. User then returns to Home Screen;
6. In the Bottom Navigation, the User is able to go into the Wall of Happiness and like a fellow Brand Ambassadors image;

1. **Route Update - Home Screen:**

1. Once the User has completed &#39;Wall of Happiness&#39;, the new module is introduced in colour and will be flashing (animated);
2. The User will be able to see their points in the top right corner, as well;
3. The User can select the &#39;Go Button&#39; to start the new module;
1. Button showing: Level | Value | Current Score Percentage;

1. **Values Voicenote:**

1. Intro to Values Voicenote;
2. Listen to continue;
3. Question (with 3 x Answer options)
4. &#39;Swipe up to Finish&#39; prompt;

1. **Route Update - Home Screen:**

1. Once the User has completed &#39;Values Voice Note&#39;, the new module is introduced in colour and will be flashing (animated);
2. The User will be able to see their points in the top right corner, as well;
3. The User can select the &#39;Go Button&#39; to start the new module;
1. Button showing: Level | Value | Current Score Percentage
2. **Knowledge Quiz:**

1. Intro to Knowledge Quiz&#39;;
2. Question 1 (with 3 x Answer options);
3. Question 2 (with 3 x Answer options);
4. Question 3 (with 2 x Answer options);
5. Question 4 (with 3 x Answer options);
6. Question 5 (with 3 x Answer options);
7. &#39;Swipe up to continue&#39; prompt;

1. **Route Update**
1. **Values VIPs:**
1. Intro to Values VIPs;
2. How are you living the values?;
3. Brand Ambassador/Colleagues quote;
4. Next Value to be added;
5. &#39;Swipe up to Finish&#39; prompt;

1. **Achievement Unlocked!**

1. First Milestone shown to the User here;
2. Badge accumulated for points;
3. User is prompted to either select &#39; Okay&#39; or close this screen with an &#39;X&#39;;

1. **Level Complete! - showing:**

1. &#39; You earned: X Points;
2. Next level opens: \*\*\*Date\*\*\*;
3. User is prompted to select &#39;Done&#39;;

1. **Route Update - Home Screen:**

1. Once the User has completed the Level, the flag module is introduced in colour and will be flashing (animated);
2. The User will be able to see their points in the top right corner, as well;
3. Where the &#39;Go Button&#39; was, the user will now see:
1. Flag showing: Next Level Opens: \*\*\*Date\*\*\* | Current Score Percentage
2. **This process is then repeated with updated content and new levels, over 30 x weeks, from 4 April - 11 November 2022.**

**5. Prizes**

Launch Giveaway (red shoe laces) will be given immediately to all employees to kick off the Campaign launch Month. (note to legal team – the dates might change but the methodology stays the same)

1. Monthly
2. In acceptance of any of the prizes resulting from any of the Competitions, the winner of such a prize agrees to take part in various publicity accompanying or resulting from this competition within reasonable limits. Such participation will be given without any further compensation.

**7. General Terms and Conditions:**

1. By submitting entries to the Prize competition all entrants agree to be bound by the rules, regulations and requirements set out in this document.

1. Each Brand Ambassador can only register or activate the App once; no multiple users or registrations will be allowed as players will have to use one single token unique number automated by the system during registration.

1. This Campaign and its Competitions are subject to South African law and are not offered outside of the Republic of South Africa.

1. Toyota SA Motors and the Administration of the Campaign reserves the right to disqualify entries based on stipulations as set out in this document.

1. No responsibility will be accepted for entries lost for any reason whatsoever.

1. Nothing on email, or on any other website of Toyota SA Motors, or entrant&#39;s use of the services or products related hereto, shall be construed as conferring any license or other rights under the intellectual property or other proprietary rights of Toyota SA Motors or its subsidiaries or affiliates or any third party, whether by estoppels, implication or otherwise. Toyota SA Motors and/or its agent shall be licensed to evaluate all contest-related submissions for the purposes of the contest, including verification and judging and as specified in these Terms and Conditions.

1. The decisions of Toyota SA Motors shall be final in all matters relating to the draw and to the winners of the Values Beat game, and no correspondence will be engaged with.

1. Toyota SA Motors reserves the right at their sole discretion to cancel, terminate or suspend the campaign and select winners from among all eligible entries received prior to the cancellation.

1. Toyota SA Motors may in their absolute discretion extend any time limit or waive any of the Terms and Conditions where a person might otherwise be disqualified.

1. Toyota SA Motors may in its absolute discretion substitute the prize deemed to be an appropriate replacement value in the place of the prize.

1. If any of the provisions in this document should be declared null or unenforceable, the validity of the other provisions and of the entire document shall not be affected or reduced.
	`}
			</ReactMarkdown>
		</div>
	);
};

export default TsAndCs;
