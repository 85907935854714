interface ICordinates {
	x: number;
	y: number;
}

// export const smallCoordinates: ICordinates[] = [
// 	{ x: 15, y: 23 },
// 	{ x: 39, y: 22 },
// 	{ x: 63, y: 28 },
// 	{ x: 54, y: 40 },
// 	{ x: 21, y: 38 },
// 	{ x: 10, y: 50 },
// 	{ x: 43, y: 55 },
// 	{ x: 70, y: 62 },
// ];

export const smallCoordinates: ICordinates[] = [
	{ x: 20, y: 68 }, //bottom
	{ x: 48, y: 65 },
	{ x: 69, y: 54 },
	{ x: 47, y: 34 },
	{ x: 17, y: 37 },
	{ x: 10, y: 16 },
	{ x: 35, y: 4 },
	{ x: 66, y: 0 }, //top
];

export const largeCoordinates: ICordinates[] = [
	{ x: 38, y: 1 },
	{ x: 30, y: 6 },
	{ x: 22, y: 11 },
	{ x: 20, y: 19 },
	{ x: 30, y: 18 },
	{ x: 41, y: 17 },
	{ x: 51, y: 16 },
	{ x: 62, y: 18 },
	{ x: 68, y: 25 },
	{ x: 65, y: 33 }, //
	{ x: 57, y: 38 },
	{ x: 47.5, y: 36.5 },
	{ x: 38, y: 35 },
	{ x: 28, y: 35 },
	{ x: 21, y: 40 },
	{ x: 16, y: 47 }, //
	{ x: 18, y: 55 }, //
	{ x: 27, y: 57 }, //
	{ x: 36.5, y: 56 }, //
	{ x: 46, y: 54.5 }, //
	{ x: 55.5, y: 54 }, //
	{ x: 65, y: 55 }, //
	{ x: 72.5, y: 59.5 }, //
	{ x: 76.3, y: 66.3 }, //
	{ x: 75, y: 73.5 }, //
	{ x: 66.7, y: 77 }, //
	{ x: 56.5, y: 76 }, //
	{ x: 45.5, y: 74 }, //
	{ x: 35, y: 71.5 }, //
	{ x: 27, y: 76 }, //
	{ x: 29.5, y: 80 }, //
];
