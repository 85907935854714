import lockSVG from 'assets/SVG/lock.svg';
import closeSVG from 'assets/SVG/modal/close.svg';

export default function ActionPrompt({
	text = '',
	bottom = 0,
	zIndex = 2,
	handleClose,
}: {
	text: string;
	bottom?: number;
	zIndex?: number;
	handleClose: any;
}) {
	return (
		<div
			style={{ background: '#E71F21', bottom, zIndex }}
			className="flex items-center justify-center pb-5 pt-5 px-4 absolute left-0 w-full"
		>
			<img src={lockSVG} alt="Lock" className="w-4" />
			<div className="ml-5 text-regular text-white">{text}</div>
			<div className="absolute top-1 right-1">
				<img src={closeSVG} alt="" className="w-6" onClick={handleClose} />
			</div>
		</div>
	);
}
