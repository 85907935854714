import React from 'react';
import backIcon from 'assets/SVG/back.svg';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';

interface ISingleVoiceNote {
	data: any;
	close: any;
}

export default function SingleVoiceNote({ data, close }: ISingleVoiceNote) {
	return (
		<div className="fixed top-0 left-0 w-full h-full bg-white px-8 pt-5 z-20">
			<button onClick={close}>
				<img src={backIcon} alt="Back icon" className="w-8" />
			</button>
			<div className="px-8 section module-content relative">
				<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
					<AudioPlayer
						artist={data.artist}
						title={data.title}
						image={data.image}
						onEnded={() => {}}
						bigPlayer
						url={data.url}
					/>
				</div>
			</div>
			{/* <div className="px-8 max-w-sm pt-20 mx-auto">
				<button onClick={close}>
					<img src={backIcon} alt="Back icon" className="w-8" />
				</button>
				<div className="w-full justify-center items-center pb-6 flex flex-col h-auto">
					<AudioPlayer
						artist={data.artist}
						title={data.title}
						image={data.image}
						onEnded={() => {}}
						bigPlayer
						url="https://www.thesoundarchive.com/ringtones/Jingle-Bells-Ella.mp3"
					/>
				</div>
			</div> */}
		</div>
	);
}
