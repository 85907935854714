import * as actionTypes from '../actions/actionTypes';

export interface IUser {
	uid: string;
	type: 'registration' | 'token' | null;
	step: number;
	achievements: string[];
	fullname: string;
	idNumber: string;
	dealerCode: number | null;
}

const initialState: IUser = {
	uid: '',
	type: null,
	fullname: '',
	idNumber: '',
	dealerCode: null,
	step: 0,
	achievements: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
