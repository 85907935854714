// import { useEffect, useState } from 'react';

// import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import ReactMarkdown from 'react-markdown';

// import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import { useAppState } from 'state';
// import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
// import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
// import LearningPath from 'hoc/LearningPath/LearningPath';
// import { useSelector } from 'react-redux';
// import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
// import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';

export default function Intro({ nextPopup }) {
	const { intro } = useAppState();
	// const { learningPaths } = useAppState();
	// const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);

	const { completeIntro } = useFirebaseAuth();
	// let { id } = useParams();
	// const data = getLearningPathData(learningPaths, id, 'knowledge-quiz') as any;
	// const [answers, setAnswers] = useState([]);

	// useEffect(() => {
	// 	if (learningPaths) {
	// 		handleStepOpen(learningPaths, data, (val) => setStep(val));
	// 	}
	// 	// eslint-disable-next-line
	// }, [data, learningPaths]);

	// const swipeCallback = () => {
	// 	handleNextStepOpen(learningPaths, data, (val) => setStep(val));
	// };

	// const handleQuizEnd = (result, isPerfect) => {
	// 	addQuizScore(result, isPerfect);
	// };

	return (
		<div style={{ zIndex: '99' }} className="fixed bg-white inset-0 m-auto top-0 left-0">
			<ReactFullpage
				licenseKey={'YOUR_KEY_HERE'}
				scrollingSpeed={500} /* Options here */
				verticalCentered={false}
				// onLeave={(origin, destination, direction) => {
				// 	if (destination.index < origin.index) {
				// 		return false;
				// 	}

				// 	if (answers[origin.index - 1] === undefined && origin.index !== 0) {
				// 		return false;
				// 	}
				// }}
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper>
							{/* Quiz page */}
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_1}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_2}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_3}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_4}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_5}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
							<FullPageSection
								showCloseButton
								closeFunction={() => {
									completeIntro();
									nextPopup();
								}}
								linkTo="/"
								swipeCallback={() => {
									completeIntro();
									nextPopup();
								}}
							>
								<div className="intro-content text-center text-gray-700 w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
									<ReactMarkdown>{intro && intro.page_6}</ReactMarkdown>

									<SwipeUpButton showIcon />
								</div>
							</FullPageSection>
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		</div>
	);
}
