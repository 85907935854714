import { Fragment, useEffect, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import achieveSVG from 'assets/SVG/modal/achieve.svg';
// import closeSVG from 'assets/SVG/modal/close.svg';
// import { setModal as setOpen } from 'store/actions/modal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import confetti from 'canvas-confetti';
import { useAppState } from 'state';

export default function Modal() {
	const achievement = useSelector<RootState>((state) => state.achievement) as any;

	const cancelButtonRef = useRef(null);
	// const [open, setOpen] = useState<boolean>(true);

	// const open: any = useSelector<RootState>((state) => state.modal);

	const { openModal, setOpenModal } = useAppState();

	useEffect(() => {
		function randomInRange(min, max) {
			return Math.random() * (max - min) + min;
		}

		if (achievement) {
			var duration = 8 * 1000;
			var animationEnd = Date.now() + duration;
			var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

			var interval = setInterval(function () {
				var timeLeft = animationEnd - Date.now();
				// var timeLeft = animationEnd;

				if (timeLeft <= 0) {
					return clearInterval(interval);
				}

				var particleCount = 150 * (timeLeft / duration);
				// since particles fall down, start a bit higher than random
				confetti(
					Object.assign({}, defaults, {
						particleCount,
						origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
						zIndex: 999,
					})
				);
				confetti(
					Object.assign({}, defaults, {
						particleCount,
						origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2, zIndex: 999 },
					})
				);
			}, 250);
		}
	}, [achievement]);

	useEffect(() => {
		if (achievement) {
			setOpenModal(true);
		}
		//eslint-disable-next-line
	}, [achievement]);

	if (!achievement) return null;

	return (
		<>
			<Transition.Root show={openModal} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 overflow-y-auto"
					initialFocus={cancelButtonRef}
					onClose={() => setOpenModal(false)}
					style={{ zIndex: 1001 }}
				>
					<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-40 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="flex flex-col justify-end fixed bg-transparent text-left overflow-hidden transition-all w-full h-screen top-0">
								<div className="bg-white w-full h-3/5">
									<img
										className="w-56 mx-auto -mt-28"
										src={achievement.imageUrl ? achievement.imageUrl : achieveSVG}
										alt=""
									/>
									<p className="text-center mt-3 text-md">NEW BADGE!</p>
									<p
										className="text-center mt-0.5 font-bold text-3xl uppercase"
										style={{ color: achievement?.messageColor }}
									>
										{achievement?.message}
									</p>
									<p className="text-center mt-1 text-xl text-lightGray">{achievement?.description}</p>

									<p className="text-center font-bold mt-4 text-3xl text-lightGray">
										You earned: <b className="text-black">{achievement?.points} POINT(S)</b>
									</p>

									<button
										type="button"
										className="font-bold bg-gradient-to-r from-purple to-darkBlue rounded-full text-white text-lg py-2 px-20 text-center flex items-center mx-auto mt-5"
										onClick={() => setOpenModal(false)}
									>
										DONE
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
