import React from 'react';
import orientationWarning from '../../assets/image/orientation-warning.png';

const OrientationWarning = () => {
	return (
		<div
			className="absolute flex m-auto inset-0 bg-black bg-opacity-50 justify-center items-center"
			style={{ zIndex: '9999' }}
		>
			<div className="w-1/2 text-white">
				<img className="mx-auto" src={orientationWarning} alt="Desktop warning" />
				<p className="text-center mt-2 font-medium text-xl">Please rotate your phone</p>
			</div>
		</div>
	);
};

export default OrientationWarning;
