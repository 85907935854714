import React from 'react';

export default function useDeviceOrientation() {
	const [orientation, setOrientation] = React.useState('');

	React.useEffect(() => {
		const handleOrientationChange = () => {
			if (window.innerHeight > window.innerWidth) {
				setOrientation('portrait');
			}

			if (window.innerHeight < window.innerWidth) {
				setOrientation('landscape');
			}
		};

		window.addEventListener('orientationchange', handleOrientationChange);

		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
		};
	}, []);

	return orientation;
}
