import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Routes from './containers/Routes';
import Layout from './hoc/Layout/Layout';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import './index.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setIsAuthReady, setUser } from 'store/actions/user';
import { RootState } from 'store/reducers';
import Loader from 'hoc/Loader/Loader';
import SplashScreen from 'components/Layout/SplashScreen/SplashScreen';
// import OptIn from 'components/Layout/OptIn/OptIn';
import UserCheck from 'hoc/UserCheck/UserCheck';
import { getLearningPaths } from 'store/actions/learningPaths';
import TermsAndConditions from 'components/Layout/TermsAndConditions/TermsAndConditions';
import Modal from 'components/Modal/Modal';
import WelcomeScreen from 'components/Layout/WelcomeScreen/WelcomeScreen';
import RegisterScreen from 'components/Layout/RegisterScreen/RegisterScreen';
// import { setUser } from 'store/actions/user';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import Intro from 'containers/pages/LearningPaths/Intro/Intro';
import DesktopWarning from 'components/DesktopWarning/DesktopWarning';
import useDeviceOrientation from 'hooks/useDeviceOrientation/useDeviceOrientation';
import OrientationWarning from 'components/OrientationWarning/OrientationWarning';
import TsAndCs from 'containers/pages/TsAndCs/TsAndCs';
import { useAppState } from 'state';

// import axios from './axios/axiosInstance'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

function App() {
	const { signIn } = useFirebaseAuth();
	const dispatch = useDispatch();
	const loading = useSelector<RootState>((state) => state.loader.loading);
	const [popup, setPopup] = useState(
		localStorage.getItem('user')
			? localStorage.getItem('terms-agreed')
				? 'splash-screen'
				: 'terms-and-conditions'
			: 'welcome'
	);
	// const [showIntro, setShowIntro] = useState(
	// 	localStorage.getItem('completed-intro') ? !JSON.parse(localStorage.getItem('completed-intro')) : true
	// );

	const { isFetching } = useAppState();

	const orientation = useDeviceOrientation();

	useEffect(() => {
		if (localStorage.getItem('user')) {
			const user = JSON.parse(localStorage.getItem('user'));

			signIn(user.idNumber);
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		app.initializeApp(firebaseConfig);
		dispatch(getLearningPaths());
		// eslint-disable-next-line
	}, []);

	return (
		<div className="App text-sm sm:text-lg">
			<Router>
				<Switch>
					<Route path="/terms-and-conditions" component={TsAndCs} />
					<Layout>
						<>
							{orientation === 'landscape' && <OrientationWarning />}
							<DesktopWarning />
							{(loading || isFetching) && <Loader />}
							<UserCheck />

							<WelcomeScreen active={popup === 'welcome'} setPopup={setPopup} />

							{/* REGISTER ROUTE */}
							<RegisterScreen active={popup === 'register'} setPopup={setPopup} />

							{/* LOGIN WITH ETOYOTA ROUTE */}
							<TermsAndConditions
								active={popup === 'terms-and-conditions'}
								nextPopup={() => setPopup('splash-screen')}
							/>
							{/* ALREADY LOGGED IN ROUTE */}
							<SplashScreen
								active={popup === 'splash-screen'}
								nextPopup={() => (localStorage.getItem('completed-intro') ? setPopup(null) : setPopup('intro'))}
							/>
							{/* <OptIn active={popup === 'opt-in'} nextPopup={() => setPopup(null)} /> */}

							{popup === 'intro' && <Intro nextPopup={() => setPopup(null)} />}
							<Modal />
							{popup === null && <Routes />}
						</>
					</Layout>
					<Redirect to="/" />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
