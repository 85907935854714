import * as actionTypes from './actionTypes';
import app from 'firebase/compat/app';
import Cookies from 'js-cookie';
import { setLoading } from './loader';
// import { setAchievement } from './achievement';

export function setWallImages(payload: any) {
	return {
		type: actionTypes.SET_WALL_IMAGES,
		payload,
	};
}

export function getWallImages() {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			let data = [];
			const snapshot = await app.firestore().collection('wall-of-happiness').where('approved', '==', true).get();

			snapshot.forEach((doc) => {
				data.unshift(doc.data());
			});

			//limit data to 50
			data = data.slice(0, 50);

			dispatch(setWallImages(data));
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(setLoading(false));
		}
	};
}

export function addImageToWall(path, uid, callback) {
	return async (dispatch) => {
		let reference: any = app.storage().ref(`/wall-of-happiness/user-upload-${uid}.png`);

		try {
			const user = JSON.parse(localStorage.getItem('user'));
			dispatch(setLoading(true));
			const { ref } = await reference.putString(path, 'data_url');
			const downloadURL = await ref.getDownloadURL();

			const timestamp = app.firestore.FieldValue.serverTimestamp();

			await app.firestore().collection('wall-of-happiness').doc(uid).set({
				uid,
				image: downloadURL,
				likes: 0,
				user: user.uid,
				timestamp,
				approved: false,
				moderated: false,
			});

			let now = new Date();
			now.setTime(now.getTime() + 1 * 3600 * 1000);

			Cookies.set('cookie-wall-image', uid, { expires: 1 / 24 });
			callback && callback();
		} catch (err) {
		} finally {
			dispatch(setLoading(false));
		}
	};
}

export function updateElement(payload) {
	return {
		type: actionTypes.UPDATE_WALL_ELEMENT,
		payload,
	};
}

export function likeImage(uid, callback, achievementCallback) {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));

			const snapshot = await app.firestore().collection('wall-of-happiness').doc(uid).get();
			const element = snapshot.data();

			element.likes = element.likes + 1;
			await app.firestore().collection('wall-of-happiness').doc(uid).set(element);

			dispatch(updateElement(element));

			let likedPhotos = JSON.parse(localStorage.getItem('liked-photos'));
			if (likedPhotos) {
				likedPhotos = [...likedPhotos, uid];
				localStorage.setItem('liked-photos', JSON.stringify(likedPhotos));
			} else {
				localStorage.setItem('liked-photos', JSON.stringify([uid]));
			}

			//Temporarily disabled

			// if (likedPhotos) {
			// 	switch (likedPhotos.length) {
			// 		case 3:
			// 			if (achievementCallback('3-likes', 1)) {
			// 				dispatch(
			// 					setAchievement(
			// 						'3-likes',
			// 						'3 Image Likes',
			// 						'#FCC725',
			// 						'You got 3 likes on your post',
			// 						1,
			// 						'/achievements/3-likes.svg'
			// 					)
			// 				);
			// 			}
			// 			break;
			// 		case 5:
			// 			if (achievementCallback('5-likes', 2)) {
			// 				dispatch(
			// 					setAchievement(
			// 						'5-likes',
			// 						'5 Image Likes',
			// 						'#279147',
			// 						'You got 5 likes on your post',
			// 						2,
			// 						'/achievements/5-likes.svg'
			// 					)
			// 				);
			// 			}
			// 			break;
			// 		case 10:
			// 			if (achievementCallback('10-likes', 3)) {
			// 				dispatch(
			// 					setAchievement(
			// 						'10-likes',
			// 						'10 Image Likes',
			// 						'#EC1C23',
			// 						'You got 10 likes on your post',
			// 						3,
			// 						'/achievements/10-likes.svg'
			// 					)
			// 				);
			// 			}
			// 			break;
			// 		case 15:
			// 			if (achievementCallback('15-likes', 4)) {
			// 				dispatch(
			// 					setAchievement(
			// 						'15-likes',
			// 						'15 Image Likes',
			// 						'#919294',
			// 						'You got 15 likes on your post',
			// 						4,
			// 						'/achievements/15-likes.svg'
			// 					)
			// 				);
			// 			}
			// 			break;
			// 		case 20:
			// 			if (achievementCallback('20-likes', 5)) {
			// 				dispatch(
			// 					setAchievement(
			// 						'20-likes',
			// 						'20 Image Likes',
			// 						'#CFA862',
			// 						'You got 20 likes on your post',
			// 						5,
			// 						'/achievements/20-likes.svg'
			// 					)
			// 				);
			// 			}
			// 			break;
			// 		default:
			// 			break;
			// 	}
			// }

			callback && callback(element);
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(setLoading(false));
		}
	};
}

export function dislikeImage(uid, callback) {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));

			const snapshot = await app.firestore().collection('wall-of-happiness').doc(uid).get();
			const element = snapshot.data();

			if (element.likes < 1) return;
			element.likes = element.likes - 1;
			await app.firestore().collection('wall-of-happiness').doc(uid).set(element);

			dispatch(updateElement(element));

			let likedPhotos = JSON.parse(localStorage.getItem('liked-photos'));
			if (likedPhotos) {
				likedPhotos = likedPhotos.filter((el) => el !== uid);
				localStorage.setItem('liked-photos', JSON.stringify(likedPhotos));
			}

			callback && callback(element);
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(setLoading(false));
		}
	};
}
