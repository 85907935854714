import * as actionTypes from '../actions/actionTypes';

// const initialState = {
// 	name: '15-perfect-quiz',
// 	message: '15 Perfect Quizes',
// 	messageColor: '#F3bF42',
// 	description: "You logged in every week for 3 weeks",
// 	points: 3,
// 	imageUrl: '/achievements/quiz/15-perfect-quiz.svg'
// };
const initialState = null;

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACHIEVEMENT:
			return action;
		default:
			return state;
	}
};

export default reducer;
