import { useEffect, useState } from 'react';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/actions/user';
import { generateLikedPhotosData } from 'helpers/utils';
import { setAchievement } from 'store/actions/achievement';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useAppState } from 'state';
dayjs.extend(weekOfYear);

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

function compare(a, b) {
	const aPoints = a.points || 0;
	const bPoints = b.points || 0;
	if (aPoints > bPoints) {
		return -1;
	} else if (bPoints > aPoints) {
		return 1;
	}
	return 0;
}

// export const activeWeek = 'week1';

export default function useFirebaseAuth() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);

	// const [cameraUplaod, setCameraUpload] = useState(false);

	const { setIsFetching, activeLevel, setActiveLevel } = useAppState();

	const [activeWeek, setActiveWeek] = useState<string>('week' + (activeLevel + 1));

	useEffect(() => {
		setActiveWeek('week' + (activeLevel + 1));
	}, [activeLevel]);

	useEffect(() => {
		app.initializeApp(firebaseConfig);
	}, []);

	const getLeaderboard = async (dealerCode) => {
		try {
			let data = [];
			const snapshot = await app
				.firestore()
				.collection('users')
				.where('fullname', '!=', 'Elmarie Vos')
				// .orderBy('achievements', 'desc')
				.get();

			snapshot.forEach((doc) => {
				data.push(doc.data());
			});

			data = data.filter((item) => {
				return !item.fullname.includes('Test');
			});

			data = data.sort(compare);
			return data;
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const getLeaderboardPosition = async (dealerCode) => {
		try {
			const users = await getLeaderboard(dealerCode);
			const uid = JSON.parse(localStorage.getItem('user')).uid;

			return users.findIndex((el) => el.uid === uid) + 1;
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const setStep = async (step: number) => {
		// setIsFetching(true);
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					weeks: {
						...user?.weeks,
						[activeWeek]: {
							...user?.weeks?.[activeWeek],
							step,
						},
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setIsFetching(false);
		}
	};

	const moveToNextLevel = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			user.activeLevel = parseInt(user.activeLevel) + 1;
			setActiveLevel(user.activeLevel);
			user.weeks[`week` + (parseInt(user.activeLevel) + 1)] = {
				step: 0,
			};

			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({ ...user });

			localStorage.setItem('user', JSON.stringify(user));
			// localStorage.setItem('step', 0 + '');
			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		}
	};

	const addAchievement = async (achievement, points) => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					achievements: [...user.achievements, achievement],
				});

			await addPoint(points);
			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const addPoint = async (number = 1) => {}
	// const addPoint = async (number = 1) => {
	// 	console.log('Adding point');
	// 	setIsFetching(true);
	// 	try {
	// 		const user = JSON.parse(localStorage.getItem('user'));
	// 		let points;

	// 		if (user.points) {
	// 			points = user.points + number;
	// 		} else {
	// 			points = number;
	// 		}

	// 		let weekPoints;

	// 		if (user.weeks[activeWeek].points) {
	// 			weekPoints = user.weeks[activeWeek].points + number;
	// 		} else {
	// 			weekPoints = number;
	// 		}

	// 		let newUser = {
	// 			...user,
	// 			weeks: {
	// 				...user?.weeks,
	// 				[activeWeek]: {
	// 					...user?.weeks?.[activeWeek],
	// 					points: weekPoints,
	// 				},
	// 			},
	// 		};

	// 		// newUser.points = points;

	// 		// *********************************

	// 		// await app
	// 		// 	.firestore()
	// 		// 	.collection('users')
	// 		// 	.doc(user.uid)
	// 		// 	.set({ ...user, points });
	// 		await app
	// 			.firestore()
	// 			.collection('users')
	// 			.doc(user.uid)
	// 			.set({ ...newUser, points });

	// 		await signIn(user.idNumber);
	// 	} catch (err) {
	// 		console.log(err);
	// 	} finally {
	// 		setIsFetching(false);
	// 	}
	// };

	//To ensure that the user can like a max of 3 posts in one day
	const addLikePoint = async (number = 1) => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			//if woh likes exist
			if (user.WOHLikes) {
				//get woh likes date
				const lastDate = user.WOHLikes[user.WOHLikes.length - 1];
				//if last like date is before today, clear likes and add point
				if (dayjs(lastDate).isBefore(dayjs(), 'day')) {
					const WOHLikes = [dayjs().format('YYYY-MM-DD')];

					await app
						.firestore()
						.collection('users')
						.doc(user.uid)
						.set({ ...user, WOHLikes });

					//save user to local storage
					localStorage.setItem('user', JSON.stringify({ ...user, WOHLikes }));
					await addPoint(number);
				} else {
					//else if likes are less than 3, add point
					if (user.WOHLikes.length < 3) {
						const WOHLikes = user.WOHLikes;
						WOHLikes.push(dayjs().format('YYYY-MM-DD'));
						await app
							.firestore()
							.collection('users')
							.doc(user.uid)
							.set({ ...user, WOHLikes });
						localStorage.setItem('user', JSON.stringify({ ...user, WOHLikes }));
						await addPoint(number);
					} else {
						//else do nothing
						return;
					}
				}
			} else {
				//Else add first like and add point

				const WOHLikes = [dayjs().format('YYYY-MM-DD')];

				await app
					.firestore()
					.collection('users')
					.doc(user.uid)
					.set({ ...user, WOHLikes });
				localStorage.setItem('user', JSON.stringify({ ...user, WOHLikes }));
				await addPoint(number);
			}

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const addQuizScore = async (score, isPerfect) => {
		console.log('Adding quiz score: ', score);
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			let newUser = {
				...user,
				weeks: {
					...user?.weeks,
					[activeWeek]: {
						...user?.weeks?.[activeWeek],
						quizScore: score,
					},
				},
			};

			await localStorage.setItem('user', JSON.stringify(newUser));

			if (isPerfect) {
				let perfectQuizes = 1;

				if (user.perfectQuizes) {
					perfectQuizes = user.perfectQuizes + 1;
				}

				newUser = {
					...user,
					quizScore: score,
					perfectQuizes,
				};

				await localStorage.setItem('user', JSON.stringify(newUser));

				if (perfectQuizes === 1) {
					dispatch(
						setAchievement(
							'1-perfect-quiz',
							'1 Perfect Quiz',
							'#FCC725',
							'You scored 5/5 in 1 quiz',
							1,
							'/achievements/quiz/1-perfect-quiz.svg'
						)
					);
					await addAchievement('1-perfect-quiz', 1);
				}
				if (perfectQuizes === 3) {
					dispatch(
						setAchievement(
							'3-perfect-quiz',
							'3 Perfect Quizes',
							'#279147',
							'You scored 5/5 in 3 quizzes',
							2,
							'/achievements/quiz/3-perfect-quiz.svg'
						)
					);
					addAchievement('3-perfect-quiz', 2);
				}
				if (perfectQuizes === 5) {
					dispatch(
						setAchievement(
							'5-perfect-quiz',
							'5 Perfect Quizes',
							'#EC1C23',
							'You scored 5/5 in 5 quizzes',
							3,
							'/achievements/quiz/5-perfect-quiz.svg'
						)
					);
					addAchievement('5-perfect-quiz', 3);
				}
				if (perfectQuizes === 10) {
					dispatch(
						setAchievement(
							'10-perfect-quiz',
							'10 Perfect Quizes',
							'#919294',
							'You scored 5/5 in 10 quizzes',
							4,
							'/achievements/quiz/10-perfect-quiz.svg'
						)
					);
					addAchievement('10-perfect-quiz', 4);
				}
				if (perfectQuizes === 15) {
					dispatch(
						setAchievement(
							'15-perfect-quiz',
							'15 Perfect Quizes',
							'#CFA862',
							'You scored 5/5 in 15 quizzes',
							5,
							'/achievements/quiz/15-perfect-quiz.svg'
						)
					);
					addAchievement('15-perfect-quiz', 5);
				}
				await app.firestore().collection('users').doc(user.uid).set(newUser);
			} else {
				await app.firestore().collection('users').doc(user.uid).set(newUser);
				console.log('Complete');

				await signIn(user.idNumber);
			}
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const addMilestone = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			const userMilestones = user.milestonesCompleted;

			let newUser;

			if (userMilestones) {
				newUser = {
					...user,
					milestonesCompleted: userMilestones + 1,
				};
			} else {
				newUser = {
					...user,
					milestonesCompleted: 1,
				};
			}

			await localStorage.setItem('user', JSON.stringify(newUser));

			if (newUser.milestonesCompleted === 1) {
				dispatch(
					setAchievement(
						'1-milestone',
						'1 Level Complete',
						'#FCC725',
						'You completed 1 level',
						1,
						'/achievements/milestone/1-milestone.svg'
					)
				);
				await addAchievement('1-milestone', 1);
			} else if (newUser.milestonesCompleted === 5) {
				dispatch(
					setAchievement(
						'5-milestone',
						'5 Levels Complete',
						'#279147',
						'You completed 5 levels',
						2,
						'/achievements/milestone/5-milestone.svg'
					)
				);
				addAchievement('5-milestone', 2);
			} else if (newUser.milestonesCompleted === 10) {
				dispatch(
					setAchievement(
						'10-milestone',
						'10 Levels Complete',
						'#EC1C23',
						'You completed 10 levels',
						3,
						'/achievements/milestone/10-milestone.svg'
					)
				);
				addAchievement('10-milestone', 3);
			} else if (newUser.milestonesCompleted === 20) {
				dispatch(
					setAchievement(
						'20-milestone',
						'20 Levels Complete',
						'#919294',
						'You completed 20 levels',
						4,
						'/achievements/milestone/20-milestone.svg'
					)
				);
				addAchievement('20-milestone', 4);
			} else if (newUser.milestonesCompleted === 30) {
				dispatch(
					setAchievement(
						'30-milestone',
						'GOLDEN TICKET',
						'#CFA862',
						'You completed 30 levels',
						5,
						'/achievements/milestone/30-milestone.svg'
					)
				);
				addAchievement('30-milestone', 5);
			} else {
				await app.firestore().collection('users').doc(user.uid).set(newUser);

				await signIn(user.idNumber);
			}
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeKnowTheBasicsQuiz = async () => {
		setIsFetching(true);
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					weeks: {
						...user?.weeks,
						[activeWeek]: {
							...user?.weeks?.[activeWeek],
							knowTheBasicsQuizComplete: true,
						},
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
			setIsFetching(false);
		}
	};

	const completeValuesViewQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					weeks: {
						...user?.weeks,
						[activeWeek]: {
							...user?.weeks?.[activeWeek],
							valuesViewQuizComplete: true,
						},
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeValuesVoiceNoteQuiz = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));
			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					weeks: {
						...user?.weeks,
						[activeWeek]: {
							...user?.weeks?.[activeWeek],
							valuesVoiceNoteQuizComplete: true,
						},
					},
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const register = async (uid, type, fullname, idNumber, dealerCode) => {
		try {
			setLoading(true);

			const timestamp = app.firestore.FieldValue.serverTimestamp();

			let user = null;
			// const snapshot = await app.firestore().collection('users').where('idNumber', '==', idNumber).limit(1).get();
			// snapshot.forEach((doc) => {
			// 	user = doc.data();
			// });

			console.log('full name', fullname);
			console.log('dealer code', dealerCode);

			//Find unique user in firestore by fullname and dealerCode
			const snapshot = await app
				.firestore()
				.collection('users')
				.where('fullname', '==', fullname)
				.where('dealerCode', '==', parseInt(dealerCode))
				.get();
			const userQueryResults = snapshot.docs.map((doc) => doc.data());

			console.log('userQueryResults', userQueryResults);

			// //If more than one user is found, set user to the oldest by timestamp
			// if (userQueryResults.length > 1) {
			// 	userQueryResults.sort((a, b) => {
			// 		return a.points - b.points;
			// 	});
			// 	user = userQueryResults[0];
			// }

			//If more than one user is found, set user to the one with the most points
			if (userQueryResults.length > 1) {
				userQueryResults.sort((a, b) => {
					return b.points - a.points;
				});
				user = userQueryResults[0];
			}

			console.log('User found: ', user);

			dealerCode = parseInt(dealerCode);

			if (user) {
				await signIn(user.idNumber);
			} else {
				await app.firestore().collection('users').doc(uid).set({
					uid,
					type,
					fullname,
					idNumber,
					dealerCode,
					achievements: [],
					weeks: [],
					timestamp,
				});

				await signIn(idNumber);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const signIn = async (idNumber) => {
		try {
			setLoading(true);

			let user = null;

			const snapshot = await app.firestore().collection('users').where('idNumber', '==', idNumber).limit(1).get();

			snapshot.forEach((doc) => {
				user = doc.data();
			});

			if (!user) {
				localStorage.removeItem('user');
				window.location.href = window.location.pathname;
			} else {
				const lastWeekUserLogin = user.lastWeekLogin;
				const userWeekLogins = user.weekLogins;
				const currentWeek = dayjs(new Date()).week();

				let newUser = user;

				if (lastWeekUserLogin && userWeekLogins) {
					if (lastWeekUserLogin !== currentWeek) {
						if (currentWeek === lastWeekUserLogin + 1) {
							newUser = {
								...user,
								lastWeekLogin: currentWeek,
								weekLogins: userWeekLogins + 1,
							};
						} else {
							newUser = {
								...user,
								lastWeekLogin: currentWeek,
								weekLogins: 1,
							};
						}
					}
				} else {
					newUser = {
						...user,
						lastWeekLogin: currentWeek,
						weekLogins: 1,
					};
				}

				if (lastWeekUserLogin !== currentWeek) {
					if (newUser.weekLogins === 2 && !newUser?.achievements?.find((el) => el === '2-logins')) {
						dispatch(
							setAchievement(
								'2-logins',
								'2 Weekly Logins',
								'#FCC725',
								'You logged in every week for 2 weeks',
								1,
								'/achievements/login/2-logins.svg'
							)
						);
						await addAchievement('2-logins', 1);
					} else if (newUser.weekLogins === 3 && !newUser?.achievements?.find((el) => el === '3-logins')) {
						dispatch(
							setAchievement(
								'3-logins',
								'3 Weekly Logins',
								'#279147',
								'You logged in every week for 3 weeks',
								2,
								'/achievements/login/3-logins.svg'
							)
						);
						await addAchievement('3-logins', 2);
					} else if (newUser.weekLogins === 4 && !newUser?.achievements?.find((el) => el === '4-logins')) {
						dispatch(
							setAchievement(
								'4-logins',
								'4 Weekly Logins',
								'#EC1C23',
								'You logged in every week for 5 weeks',
								3,
								'/achievements/login/4-logins.svg'
							)
						);
						await addAchievement('4-logins', 3);
					} else if (newUser.weekLogins === 5 && !newUser?.achievements?.find((el) => el === '5-logins')) {
						dispatch(
							setAchievement(
								'5-logins',
								'5 Weekly Logins',
								'#919294',
								'You logged in every week for 10 weeks',
								4,
								'/achievements/login/5-logins.svg'
							)
						);
						await addAchievement('5-logins', 4);
					} else if (newUser.weekLogins === 10 && !newUser?.achievements?.find((el) => el === '10-logins')) {
						dispatch(
							setAchievement(
								'10-logins',
								'10 Weekly Logins',
								'#CFA862',
								'You logged in every week for 15 weeks',
								5,
								'/achievements/login/10-logins.svg'
							)
						);
						await addAchievement('10-logins', 5);
					}
				}

				if (newUser.activeLevel === undefined) {
					newUser.activeLevel = 0;
				}

				setActiveLevel(newUser.activeLevel);

				newUser.last_active = app.firestore.FieldValue.serverTimestamp();

				//Get weeks since 1 april
				const weeksSinceStart = dayjs(new Date()).diff(dayjs('2022-04-04'), 'week') - 1;
				console.log('Weeks since start', weeksSinceStart);

				if (newUser.activeLevel > weeksSinceStart) {
					//PROD
					newUser.activeLevel = weeksSinceStart;
				}

				await app.firestore().collection('users').doc(user.uid).set(newUser);

				dispatch(setUser(newUser));

				localStorage.setItem('step', user?.weeks[`week${newUser.activeLevel + 1}`]?.step || 0);

				if (user?.weeks[activeWeek]?.quizScore) {
					console.log('Setting quiz score', user?.weeks[activeWeek]?.quizScore);
					localStorage.setItem('quiz-score', user?.weeks[activeWeek]?.quizScore);
				}
				if (user?.weeks[activeWeek]?.knowTheBasicsQuizComplete) {
					localStorage.setItem('know-the-basics-quiz-complete', user?.weeks[activeWeek]?.knowTheBasicsQuizComplete);
				}
				if (user?.weeks[activeWeek]?.valuesViewQuizComplete) {
					localStorage.setItem('values-view-quiz-complete', user?.weeks[activeWeek]?.valuesViewQuizComplete);
				}
				if (user?.weeks[activeWeek]?.valuesVoiceNoteQuizComplete) {
					localStorage.setItem('values-voice-note-quiz-complete', user?.weeks[activeWeek]?.valuesVoiceNoteQuizComplete);
				}
				if (user.completedIntro) {
					localStorage.setItem('completed-intro', user.completedIntro);
				}

				generateLikedPhotosData(user);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const uploadImageToWallOfHappiness = async (path, uid) => {
		let reference: any = app.storage().ref(`/wall-of-happiness/user-upload-${uid}.png`);

		try {
			const user = JSON.parse(localStorage.getItem('user'));

			setLoading(true);
			const { ref } = await reference.putString(path, 'data_url');
			const downloadURL = await ref.getDownloadURL();

			const timestamp = app.firestore.FieldValue.serverTimestamp();

			await app.firestore().collection('wall-of-happiness').doc(uid).set({
				uid,
				image: downloadURL,
				likes: 0,
				user: user.uid,
				timestamp,
			});
		} catch (err) {
		} finally {
			setLoading(false);
		}
	};

	const getImagesFromWallOfHappiness = async () => {
		try {
			setLoading(true);
			let data = [];
			const snapshot = await app.firestore().collection('wall-of-happiness').get();

			snapshot.forEach((doc) => {
				data.push(doc.data());
			});

			return data;
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const incraseLikes = async (uid) => {
		try {
			const snapshot = await app.firestore().collection('wall-of-happiness').doc(uid).get();

			const element = snapshot.data();

			element.likes = element.likes + 1;
			await app.firestore().collection('wall-of-happiness').doc(uid).set(element);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	const completeIntro = async () => {
		try {
			const user = JSON.parse(localStorage.getItem('user'));

			await app
				.firestore()
				.collection('users')
				.doc(user.uid)
				.set({
					...user,
					completedIntro: true,
				});

			await signIn(user.idNumber);
		} catch (err) {
			console.log(err);
		} finally {
			// setLoading(false);
		}
	};

	return {
		register,
		signIn,
		setStep,
		addAchievement,
		getLeaderboard,
		getLeaderboardPosition,
		completeKnowTheBasicsQuiz,
		completeValuesViewQuiz,
		completeValuesVoiceNoteQuiz,
		completeIntro,
		activeWeek,
		moveToNextLevel,

		loading,
		uploadImageToWallOfHappiness,
		getImagesFromWallOfHappiness,
		incraseLikes,
		addQuizScore,
		addMilestone,
		addPoint,
		addLikePoint,
		// cameraUplaod,
		// setCameraUpload
	};
}
