export const GET_WALL_IMAGES: string = 'GET_WALL_IMAGES';
export const SET_WALL_IMAGES: string = 'SET_WALL_IMAGES';
export const UPDATE_WALL_ELEMENT: string = 'UPDATE_WALL_ELEMENT';

export const SET_USER: string = 'SET_USER';
export const SET_IS_AUTH_READY: string = 'SET_IS_AUTH_READY';

export const SET_LOADING: string = 'SET_LOADING';

export const SUCCESS_NOTIFICATION: string = 'SUCCESS_NOTIFICATION';
export const ERROR_NOTIFICATION: string = 'ERROR_NOTIFICATION';

export const SET_LEARNING_PATHS: string = 'SET_LEARNING_PATHS';

export const SET_ACHIEVEMENT: string = 'SET_ACHIEVEMENT';

export const SET_MODAL: string = 'SET_MODAL';
