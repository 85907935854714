import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function About() {
	const [about, setAbout] = React.useState();

	React.useEffect(() => {
		axios.get(process.env.REACT_APP_CMS_URL + '/infos').then((res) => {
			setAbout(res.data[1].Content);
		});
	}, []);

	// const videoRef = useRef(null);
	// const [videoPlaying, setVideoPlaying] = useState(false);
	// const [fullscreen, setFullscreen] = useState(false);
	// eslint-disable-next-line
	// const [videoCompleted, setVideoCompleted] = useState(false);

	// const handleClickFullscreen = () => {
	// 	setFullscreen(!fullscreen);
	// 	var vid = document.querySelector('video');
	// 	// vid.removeAttribute("controls");
	// 	var el = document.querySelector('#video');

	// 	if (fullscreen) {
	// 		vid.classList.remove('fullscreen');
	// 		if (document.exitFullscreen) {
	// 			document.exitFullscreen();
	// 		} else if (document['webkitExitFullscreen']) {
	// 			/* Safari */
	// 			document['webkitExitFullscreen']();
	// 		} else if (document['msExitFullscreen']) {
	// 			/* IE11 */
	// 			document['msExitFullscreen']();
	// 		}
	// 	} else {
	// 		vid.classList.add('fullscreen');
	// 		if (el.requestFullscreen) {
	// 			el.requestFullscreen();
	// 		} else if (el['msRequestFullscreen']) {
	// 			el['msRequestFullscreen']();
	// 		} else if (el['mozRequestFullScreen']) {
	// 			el['mozRequestFullScreen']();
	// 		} else if (el['webkitRequestFullscreen']) {
	// 			el['webkitRequestFullscreen']();
	// 		}
	// 	}
	// };

	return (
		<div className="px-8 pb-20 about pt-12">
			<ReactMarkdown>{about}</ReactMarkdown>
			{/* <h1 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">ABOUT PEOPLE+</h1>
			<p className="mb-4">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia libero quae delectus sequi modi dolores iste quam,
				quisquam quasi, soluta maiores dolorem corporis voluptatibus blanditiis veniam eligendi, earum nobis voluptates?
			</p>
			<h1 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">ABOUT THE WOVEN CITY</h1>
			<div className="w-full md:w-60 justify-center items-center mb-4 flex flex-col h-auto">
				<div className="relative" id="video">
					<ReactPlayer
						ref={videoRef}
						width="fite-content"
						height={'auto'}
						playing={videoPlaying}
						onEnded={() => setVideoCompleted(true)}
						url={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'}
					/>
					<button
						className="absolute bottom-2 right-0 left-2"
						onClick={() => {
							setVideoPlaying((curr) => !curr);
						}}
					>
						{videoPlaying ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-12 w-12 text-white"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
									clipRule="evenodd"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-12 w-12 text-white"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
									clipRule="evenodd"
								/>
							</svg>
						)}
					</button>
					<button className="absolute bottom-2 right-2" onClick={handleClickFullscreen}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-8 w-8 text-white"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
							/>
						</svg>
					</button>
				</div>
			</div>
			<p className="mb-4">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis tempore inventore aspernatur reprehenderit in
				quia error dolorem ducimus a debitis? Deserunt fuga dolores doloremque explicabo nihil minima dicta quis
				delectus?
			</p>
			<h1 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">VALUES VAULT</h1>
			<p className="mb-4">
				Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia adipisci sit quas placeat sunt quam laudantium
				perferendis dignissimos ullam veritatis!
			</p> */}
		</div>
	);
}
