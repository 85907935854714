import * as actionTypes from '../actions/actionTypes';

const initialState = false;

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_MODAL:
			// console.log('returning 1', action.payload);
			return action.payload;
		default:
			// console.log('returning 2', state);
			return state;
	}
};

export default reducer;
