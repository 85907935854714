import { Formik, Form, Field, ErrorMessage } from 'formik';
import { number, object, string } from 'yup';

import toyotaTouchImage from 'assets/image/toyota-touch_V02.png';
import leftBottom from 'assets/SVG/splashScreen/left-bottom.svg';
import rightBottom from 'assets/SVG/splashScreen/right-bottom.svg';
import Header from '../Header/Header';

// import dealerCodes from './dealerCodes';
import dealersData from 'dealersData.json';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { UID } from 'hooks/useWallElements/useWallElements';
import { useState } from 'react';

const validationSchema = () =>
	object().shape({
		firstname: string().required('Firstname is required'),
		surname: string().required('Surname is required'),
		idNumber: string().required('ID Number is required'),
		dealerCode: number().required('Dealer code is required '),
	});

const initialValues = {
	firstname: '',
	surname: '',
	idNumber: '',
	dealerCode: '',
};

export default function RegisterScreen({ active, setPopup }) {
	const [validationError, setValidationError] = useState<number>(0);
	const { register } = useFirebaseAuth();

	if (!active) {
		return null;
	}

	const handleSubmit = async (values) => {
		if (localStorage.getItem('blocked')) return;

		const { dealerCode } = values;

		if (dealerCode && !dealersData.find((el) => el.code === dealerCode)) {
			if (validationError === 2) {
				localStorage.setItem('blocked', 'true');
				window.location.href = window.location.pathname;
			} else {
				setValidationError((p) => p + 1);
			}
		} else {
			await register(
				UID(),
				'registration',
				`${values.firstname} ${values.surname}`,
				values.idNumber,
				values.dealerCode
			);
			setPopup('terms-and-conditions');
		}
	};

	const validate = async (values) => {};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validate={validate}
		>
			<Form>
				<Header zIndex={110} />
				<div
					className={
						'fixed w-full h-full top-0 left-0 bg-white flex-col justify-center items-center ' +
						(active ? 'flex' : 'hidden')
					}
					style={{ zIndex: 1000 }}
				>
					<div onClick={() => setPopup('welcome')}>
						<img src={toyotaTouchImage} alt="People plus" className="w-64 mb-12" />
					</div>

					<div className="text-3xl font-bold mb-5">Please enter your details</div>

					<Field type="text" name="firstname" placeholder="FIRST NAME" className="rounded-3xl w-4/5 mt-2 text-sm" />
					<div className="text-sm text-red-500 pt-1">
						<ErrorMessage name="firstname" />
					</div>
					<Field type="text" name="surname" placeholder="SURNAME" className="rounded-3xl w-4/5 mt-2 text-sm" />
					<div className="text-sm text-red-500 pt-1">
						<ErrorMessage name="surname" />
					</div>
					<Field
						type="number"
						name="idNumber"
						placeholder="ID NUMBER / PASSPORT NUMBER"
						className="rounded-3xl w-4/5 mt-2 text-sm"
					/>
					<div className="text-sm text-red-500 pt-1">
						<ErrorMessage name="idNumber" />
					</div>
					<Field type="number" name="dealerCode" placeholder="DEALER CODE" className="rounded-3xl w-4/5 mt-2 text-sm" />
					<div className="text-sm text-red-500 pt-1">
						<ErrorMessage name="dealerCode" />

						{validationError && !localStorage.getItem('blocked') ? (
							<p>
								Dealer code wrong for the {validationError} time, {3 - validationError} tries left
							</p>
						) : null}
					</div>

					<div className="text-sm w-3/4 text-center mt-3 text-gray-500">
						Don't know your dealer code? Please ask your manager.
					</div>

					<button
						type="submit"
						className={`font-extrabold bg-gradient-to-r z-50 from-purple to-darkBlue rounded-full text-white text-lg uppercase px-20 py-3 text-center flex items-center justify-center mt-10 cursor-pointer`}
					>
						LOG IN
					</button>
					{localStorage.getItem('blocked') ? (
						<p className="text-md text-red-500 pt-5">Registration locked after 3 failed attempts</p>
					) : null}

					<img src={leftBottom} alt="Left bot" className="absolute bottom-0 left-0 w-32" />
					<img src={rightBottom} alt="Right bot" className="absolute bottom-0 right-0 w-32" />
				</div>
			</Form>
		</Formik>
	);
}
