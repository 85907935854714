import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';
import { useAppState } from 'state';
// import { activeWeek } from 'state/useFirebase/useFirebase';

export default function KnowTheBasics({ history }) {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths) as any;
	const [data, setData] = useState() as any;
	const [answer, setAnswer] = useState(null);
	const [notFinished, setNotFinished] = useState(false);
	const [showQuiz, setShowQuiz] = useState(true);

	const { activeLevel } = useAppState();

	const { setStep, completeKnowTheBasicsQuiz, addPoint } = useFirebaseAuth();

	let { id } = useParams();
	useEffect(() => {
		setData(getLearningPathData(learningPaths, id, 'know-the-basics'));
		setShowQuiz(
			!JSON.parse(localStorage.getItem('user'))?.weeks?.[`week${activeLevel + 1}`]?.knowTheBasicsQuizComplete
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) === getCurrentStep(learningPaths, data)?.index);

			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	// useEffect(() => {
	// 	console.log(notFinished);
	// }, [notFinished]);

	const swipeCallback = async () => {
		console.log("-Executing callback");
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		console.log("-Next step opened");
		// await completeKnowTheBasicsQuiz();

		console.log("-Not finished:", notFinished);

		if (notFinished) {
			console.log("Going to add point");
			await addPoint();
			console.log("Point added");
		}
	};

	// const knowTheBasicsQuizComplete = JSON.parse(localStorage.getItem('user'))?.weeks?.[`week${activeLevel + 1}`]
	// 	?.knowTheBasicsQuizComplete;

	return (
		<>
			<LearningPath>
				{data && (
					<ReactFullpage
						licenseKey={'YOUR_KEY_HERE'}
						scrollingSpeed={500} /* Options here */
						verticalCentered={false}
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									{/* know the basics page */}
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											{/* <h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">
												{data.intro}
											</h2> */}
											<div className="mt-0">
												<img className="w-3/4 mx-auto" src={data.featured_image.url} alt="" />
											</div>
											<p className="mt-2 text-sm sm:text-lg text-center">{data.introduction}</p>

											<SwipeUpButton
												showIcon
											// hide={true}
											/>
										</div>
									</FullPageSection>
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<div>
												<ReactMarkdown className="text-sm sm:text-lg">{data.value_statement}</ReactMarkdown>
											</div>
											<SwipeUpButton
												showIcon
											// hide={true}
											/>
										</div>
									</FullPageSection>
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="text-sm sm:text-lg">{data.example}</ReactMarkdown>
											<SwipeUpButton
												showIcon
											// hide={true}
											/>
										</div>
									</FullPageSection>
									{data.example_2 && (
										<FullPageSection
											linkTo={!showQuiz ? '/' : null}
											swipeCallback={!showQuiz ? swipeCallback : () => { }}
										>
											<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
												<ReactMarkdown className="text-sm sm:text-lg">{data.example_2}</ReactMarkdown>
												{!showQuiz ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
											</div>
										</FullPageSection>
									)}
									{showQuiz && (
										<FullPageSection
											promptText="Please select an answer to continue"
											linkTo={answer !== null && `/`}
											// swipeCallback={swipeCallback}
											promptActive={!answer}
										>
											<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
												<h2 className="text-lg sm:text-2xl text-lightGray font-bold">POP QUIZ</h2>

												<p className="sm:text-lg">{data?.question?.question_text}</p>
												<KnowledgeQuizForm
													answers={data?.question?.answers}
													isImageQuestion={data?.question?.answers[0].type === 'image'}
													onSelect={async (answer) => {
														setAnswer(answer);
														await completeKnowTheBasicsQuiz();
														await swipeCallback();
													}}
												/>
												{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
											</div>
										</FullPageSection>
									)}

									{/* end of know the basics pages */}
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				)}
			</LearningPath>
		</>
	);
}
