import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { RootState } from 'store/reducers';
import dealersData from 'dealersData.json';

function shuffle(array) {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex !== 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

export default function Finishers() {
	const { getLeaderboard } = useFirebaseAuth();

	const user: any = useSelector<RootState>((state) => state.user);

	const [users, setUsers] = useState([]);

	useEffect(() => {
		if (user) {
			(async function () {
				setUsers(await getLeaderboard(user.dealerCode));
			})();
		}
		// eslint-disable-next-line
	}, [user]);

	return (
		<div className="w-full md:w-60 justify-center items-center py-6 px-2  flex flex-col h-auto pb-20">
			<h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">Finishers</h2>
			<div className="w-full md:w-60 justify-center items-center px-2">
				{/* {users.map((el, index) => ( */}
				<div>
					<ul className="flex flex-col bg-white-200 p-2 mb-5">
						{users &&
							shuffle(users).slice(0, 50).filter(user => Boolean(user.fullname)).map((user, index) => (
								<li className="select-none cursor-pointer flex-1 items-center px-0 py-1 border-b-2 flex flex-row">
									{/* <div className="flex flex-col justify-items-start items-start mr-4">
										<h3 className="text-red-400 font-semibold w-7 text-center">{index + 1}</h3>
									</div> */}
									{/* <div className="flex flex-col rounded-full w-5 h-10 bg-gray-200 justify-center items-center mr-4">
										<img className="w-full h-full rounded-full" src={userIconSVG} alt="" />
									</div> */}
									<div className="flex-1 pl-1 mr-16 py-2">
										{/* <div className="flex items-center"> */}
										<div className="font-medium">{user.fullname}</div>
										<div className="text-sm text-gray-600 h-4">
											{dealersData.find((el) => el.code === user.dealerCode)?.name}
										</div>
										{/* </div> */}

										<div className="text-gray-600 text-sm flex items-center justify-items-start"></div>
									</div>
								</li>
							))}
					</ul>
				</div>
				{/* ))} */}
			</div>
		</div>
	);
}
