import swipeSVG from 'assets/SVG/swipeUp/swipe-up-dark.svg';
import swipeWhiteSVG from 'assets/SVG/swipeUp/swipe-up-light.svg';

export default function SwipeUpButton({ showIcon, white = false }: { showIcon?: boolean; white?: boolean }) {
	return (
		<div
			className={
				'swipe-up visible flex flex-wrap justify-center text-gray-50 py-4 px-4 text-center  absolute bottom-2 sm:bottom-8 items-center ' +
				(white ? 'text-2xl' : 'text-sm')
			}
		>
			<div className="mx-1">
				<p className={'font-semibold py-4 px-4 bottom-40 ' + (white ? 'text-white' : 'text-gray-800')}>
					Swipe up to continue
				</p>
			</div>
			{showIcon && (
				<div className="mx-1">
					<img src={white ? swipeWhiteSVG : swipeSVG} alt="" className={white ? 'w-12' : 'w-8'} />
				</div>
			)}
		</div>
	);
}
