/* eslint-disable */
import { useState } from 'react';
// import { Link } from 'react-router-dom';
import leftBottom from 'assets/SVG/splashScreen/left-bottom.svg';
import rightBottom from 'assets/SVG/splashScreen/right-bottom.svg';
import Header from '../Header/Header';

export default function TermsAndConditions({ active, nextPopup }) {
	const [agree, setAgree] = useState<boolean>(false);

	if (!active) {
		return null;
	}

	return (
		<>
			<Header zIndex={110} />
			<div
				className={
					'fixed w-full h-full top-0 left-0 bg-white flex-col justify-center items-center ' +
					(active ? 'flex' : 'hidden')
				}
				style={{ zIndex: 1000 }}
			>
				<div className="p-7 z-50">
					<h3 className="uppercase text-darkBlue text-sm mt-0 font-bold py-2 w-2/3">
						Please connect to Wifi to limit your data usage
					</h3>

					<p>
						Data usage is how much data your phone uploads or downloads using mobile data. To make sure that you're not
						using too much data on your data plan, please connect to Wifi while using this web application.
					</p>

					<input
						className="form-check-input appearance-none z-50 h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-darkBlue checked:border-darkBlue focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
						type="checkbox"
						checked={agree}
						onChange={(e) => setAgree(e.target.checked)}
						id="flexCheckChecked"
					/>

					<label
						className="form-check-label z-50 inline-block text-darkBlue font-thin opacity-40"
						htmlFor="flexCheckChecked"
					>
						I agree to the{' '}
						<a
							target="_blank"
							href="https://docs.google.com/document/d/1cBhBV62TKoNYMtajprXzxchE1jCo5QtDQzfFGn3He2g/edit"
							className="underline"
						>
							T's & C's
						</a>
					</label>

					<button
						disabled={!agree}
						onClick={() => {
							nextPopup();
							localStorage.setItem('terms-agreed', 'true');
						}}
						className={`font-extrabold z-50 bg-gradient-to-r from-purple to-darkBlue rounded-full text-white text-sm uppercase px-8 py-3 text-center flex items-center justify-center mt-5 ${
							!agree ? 'opacity-60 pointer-events-none' : ''
						}`}
					>
						Continue
					</button>
				</div>

				<img src={leftBottom} alt="Left bot" className="absolute bottom-0 left-0 w-32" />
				<img src={rightBottom} alt="Right bot" className="absolute bottom-0 right-0 w-32" />
			</div>
		</>
	);
}
