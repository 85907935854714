import React, { useState } from 'react';

import Header from '../../components/Layout/Header/Header';
import NavBar from '../../components/Layout/NavBar/NavBar';
import UserSidebar from '../../components/Layout/UserSidebar/UserSidebar';

export default function Layout({ children }: { children: React.ReactChild }) {
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

	return (
		<>
			<Header setIsSidebarOpen={setIsSidebarOpen} />
			<UserSidebar open={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
			{children}
			<NavBar />
		</>
	);
}
