import React, { useRef } from 'react';

import largeAlbumSvg from 'assets/SVG/player/large-album.svg';
// import playSVG from 'assets/SVG/player/play.svg';
// import pauseSVG from 'assets/SVG/player/pause.svg';
// import restartSVG from 'assets/SVG/player/restart.svg';
// import { setAchievement } from 'store/actions/achievement';
// import { useDispatch } from 'react-redux';

interface IAudioPlayer {
	url: string;
	title?: string;
	artist?: string;
	onEnded: () => any;
	image?: any;
	bigPlayer?: boolean;
}
export default function AudioPlayer({
	url,
	title = 'title',
	artist = 'artist',
	onEnded = () => {},
	image = largeAlbumSvg,
	bigPlayer = false,
}: IAudioPlayer) {
	// const dispatch = useDispatch();

	const player = useRef<HTMLVideoElement>();

	// const [playing, setPlaying] = useState(false);

	return (
		<div className={'flex flex-col items-center' + (bigPlayer ? ' w-full' : '')}>
			<p className="py-4 mt-2 text-1xl font-bold text-gray-500 uppercase">{title}</p>
			<h5 className="-mt-2 text-gray-500 font-semibold">{artist}</h5>
			<div className={'flex justify-center items-center ' + (bigPlayer ? 'w-full mt-3' : 'h-44 w-44')}>
				<img src={image} alt="" className="w-52 h-full object-cover" />
			</div>
			<audio className="mt-8" onEnded={onEnded} ref={player} controls>
				<source src={url} type="audio/mpeg" />
				Your browser does not support the audio element.
			</audio>

			{/* <div className="flex flex-row justify-center mt-8">
				<button
					onClick={() => {
						if (playing) {
							player.current.pause();
							setPlaying(false);
						} else {
							player.current.play();
							setPlaying(true);
							bigPlayer && dispatch(setAchievement('music-played', 'First Music Played'));
						}
					}}
				>
					{playing ? (
						<img src={pauseSVG} alt="" className="w-8 h-9 my-2 mr-8" />
					) : (
						<img src={playSVG} alt="" className="w-8 h-9 my-2 mr-8" />
					)}
				</button>

				<button
					onClick={() => {
						player.current.currentTime = 0;
					}}
				>
					<img src={restartSVG} alt="" className="w-8 my-2" />
					
				</button>
			</div> */}
		</div>
	);
}
