import React, { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import QuickQuizForm from 'components/QuickQuizForm/QuickQuizForm';
import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';

export default function QuickQuiz() {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);

	const { setStep, addPoint } = useFirebaseAuth();

	const [answers, setAnswers] = useState([]);
	const [notFinished, setNotFinished] = useState(false);

	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'quick-quiz') as any;

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	// useEffect(() => {
	// 	console.log(notFinished);
	// }, [notFinished]);

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		if (notFinished) {
			await addPoint();
		}
	};

	return (
		<>
			<LearningPath>
				<ReactFullpage
					licenseKey={'YOUR_KEY_HERE'}
					scrollingSpeed={500}
					verticalCentered={false}
					onLeave={(origin, destination, direction) => {
						if (destination.index < origin.index) {
							return false;
						}

						//If no answer at index and not coming from first slide
						if (answers[origin.index - 1] === undefined && origin.index !== 0) {
							return false;
						}

						if (origin.isLast) {
							// setLastScroll(true);
						}
					}}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								{/* Quiz page */}

								{parseInt(localStorage.getItem('step')) <= getCurrentStep(learningPaths, data).index + 1 && (
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 pt-8 sm:pt-16 flex flex-col h-auto">
											<div>
												<img
													className="mx-auto w-8/12 mt-8"
													src={learningPaths ? data.featured_image.url : ''}
													alt=""
												/>
											</div>
											<p className="text-sm sm:text-lg">{learningPaths ? data.introduction : ''}</p>
											<SwipeUpButton />
										</div>
									</FullPageSection>
								)}
								{parseInt(localStorage.getItem('step')) <= getCurrentStep(learningPaths, data).index + 1 &&
									data.question.map((question, index) => {
										return (
											<FullPageSection
												promptText="Please select an answer to continue"
												promptActive={answers[index] !== undefined ? false : true}
												key={index}
											>
												<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
													<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question {index + 1}</h2>

													<p className="sm:text-lg">{question.question_text}</p>
													<QuickQuizForm
														answers={question.answers}
														isImageQuestion={question.answers[0].type === 'image'}
														onSelect={async (answer) => {
															await setAnswers((curr) => {
																if (curr[index]) {
																	return [...curr.slice(0, index), answer, ...curr.slice(index + 1)];
																} else {
																	return [...curr, answer];
																}
															});
															fullpageApi.moveSectionDown();
														}}
													/>
													{answers[index] != null ? <SwipeUpButton /> : <SelectAnswerButton />}
												</div>
											</FullPageSection>
										);
									})}
								<FullPageSection linkTo={`/`} swipeCallback={swipeCallback}>
									<div className="w-full md:w-60 justify-center items-center px-2 pt-6 sm:pt-16 flex flex-col h-auto">
										<img
											className="my-4 w-2/4 sm:w-3/4"
											src={
												data.results.filter(
													(x) =>
														answers.filter((x) => x === true).length >= x.range_start &&
														answers.filter((x) => x === true).length <= x.range_end
												)[0].result_image.url
											}
											alt=""
										/>

										<ReactMarkdown>
											{
												data.results.filter(
													(x) =>
														answers.filter((x) => x === true).length >= x.range_start &&
														answers.filter((x) => x === true).length <= x.range_end
												)[0].result_text
											}
										</ReactMarkdown>
										<FinishModuleButton />
									</div>
								</FullPageSection>
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</LearningPath>
		</>
	);
}
