import React from 'react';

import { Route } from 'react-router-dom';
import { Home, Finishers, WallOfHappiness as WallOfHappinessPage, About, ValuesVoiceNotes } from './pages';
import QuickQuiz from './pages/LearningPaths/QuickQuiz/QuickQuiz';
import KnowTheBasics from './pages/LearningPaths/KnowTheBasics/KnowTheBasics';
import ValuesView from './pages/LearningPaths/ValuesView/ValuesView';
import WallOfHappiness from './pages/LearningPaths/WallOfHappiness/WallOfHappiness';
import ValuesVoiceNote from './pages/LearningPaths/ValuesVoiceNote/ValuesVoiceNote';
import KnowledgeQuiz from './pages/LearningPaths/KnowledgeQuiz/KnowledgeQuiz';
import NewsFlash from './pages/LearningPaths/NewsFlash/NewsFlash';
import Teaser from './pages/LearningPaths/Teaser/Teaser';
import WallOfHappinessIntro from './pages/LearningPaths/WallOfHappinessIntro/WallOfHappinessIntro';

export default function Routes() {
	return (
		<>
			<Route path="/learning-path/:id/know-the-basics" component={KnowTheBasics} />
			<Route path="/learning-path/:id/quick-quiz" component={QuickQuiz} />
			<Route path="/learning-path/:id/values-view" component={ValuesView} />
			<Route path="/learning-path/:id/wall-of-happiness" component={WallOfHappinessIntro} />
			<Route path="/learning-path/:id/wall-of-happiness-images" component={WallOfHappiness} />
			<Route path="/learning-path/:id/wall-of-happiness-design" component={WallOfHappinessPage} />
			<Route path="/learning-path/:id/values-voice-notes" component={ValuesVoiceNote} />
			<Route path="/learning-path/:id/knowledge-quiz" component={KnowledgeQuiz} />
			<Route path="/learning-path/:id/news-flash" component={NewsFlash} />
			<Route path="/learning-path/:id/teaser" component={Teaser} />
			<Route path="/leaderboard" component={Finishers} />
			<Route path="/wall-of-happiness" component={WallOfHappiness} />
			<Route path="/about" component={About} />
			<Route path="/values-voicenotes" component={ValuesVoiceNotes} />

			<Route path="/" exact component={Home} />
		</>
	);
}
