import toyotaTouchImage from 'assets/image/toyota-touch_V02.png';
import leftBottom from 'assets/SVG/splashScreen/left-bottom.svg';
import rightBottom from 'assets/SVG/splashScreen/right-bottom.svg';
import Header from '../Header/Header';

export default function WelcomeScreen({ active, setPopup }) {
	if (!active) {
		return null;
	}

	return (
		<>
			<Header zIndex={110} />
			<div
				className={
					'fixed w-full h-full top-0 left-0 bg-white flex-col justify-center items-center ' +
					(active ? 'flex' : 'hidden')
				}
				style={{ zIndex: 1000 }}
			>
				<img src={toyotaTouchImage} alt="People plus" className="w-64 mb-24" />

				<div className="text-3xl font-bold mb-1">Welcome!</div>
				<div className="text-2xl text-gray-700">Please log in to continue</div>

				<a
					href={process.env.REACT_APP_REDIRECT}
					className={`font-extrabold bg-gradient-to-r from-purple to-darkBlue rounded-full text-white text-lg px-10 py-3 text-center flex items-center justify-center mt-8 cursor-pointer`}
				>
					LOG IN WITH e-Toyota
				</a>

				<div className="text-md mb-32 mt-3 text-gray-500 cursor-pointer" onClick={() => setPopup('register')}>
					No e-Toyota account?
				</div>

				<img src={leftBottom} alt="Left bot" className="absolute bottom-0 left-0 w-32" />
				<img src={rightBottom} alt="Right bot" className="absolute bottom-0 right-0 w-32" />
			</div>
		</>
	);
}
