import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppStateProvider from './state';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from 'store/reducers';
// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AppStateProvider>
					<App />
				</AppStateProvider>
			</Provider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	</StrictMode>,
	document.getElementById('root')
);
