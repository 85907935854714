// import wovenCity from 'assets/SVG/loader/wovenCity.svg';
// import peoplePlus from 'assets/SVG/header/people-plus-logo.svg';
import './style.css';
import { useEffect } from 'react';
// import leftBottom from 'assets/SVG/splashScreen/left-bottom.svg';
// import rightBottom from 'assets/SVG/splashScreen/right-bottom.svg';
import Header from '../Header/Header';

export default function SplashScreen({ active, nextPopup }) {
	useEffect(() => {
		if (active) {
			let timer = setTimeout(() => {
				nextPopup();
			}, 2500);
			return () => {
				clearTimeout(timer);
			};
		}
		//eslint-disable-next-line
	}, [active]);

	if (!active) {
		return null;
	}

	return (
		<>
			<Header zIndex={110} />
			<div
				className={
					'fixed w-full h-full splash-screen top-0 left-0 bg-white z-40 flex-col justify-center items-center flex'
				}
				style={{ zIndex: 100 }}
			>
				{/* <img src={wovenCity} alt="Logo" className="w-56 mb-8" />
				<img src={peoplePlus} alt="People plus" className="w-56" />
				<div className="w-56 text-2xl text-center mt-3">My Journey To The Woven City</div>
				<div className="splash-loader mt-8 bg-white">
					<div className="splash-loader-before"></div>
					<div className="splash-loader-after"></div>
				</div>
				<img src={leftBottom} alt="Left bot" className="absolute bottom-0 left-0 w-32" />
				<img src={rightBottom} alt="Right bot" className="absolute bottom-0 right-0 w-32" /> */}
			</div>
		</>
	);
}
