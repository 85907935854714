import { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Line, Text, Rect } from 'react-konva';
import { generateRandomHEXColor } from 'helpers/utils';
import useWindowSize from 'hooks/useWindowSize/useWindowSize';
// import EXIF from 'exif-js';

import pencilSVG from 'assets/SVG/wallOfHappiness/pencil.svg';
import eraserSVG from 'assets/SVG/wallOfHappiness/eraser.svg';
import textSVG from 'assets/SVG/wallOfHappiness/text.svg';
import undoSVG from 'assets/SVG/wallOfHappiness/undo.svg';
import trashSVG from 'assets/SVG/wallOfHappiness/trash.svg';
// import uploadSVG from 'assets/SVG/wallOfHappiness/upload.svg';
import SubmitButton from 'components/Buttons/SubmitButton/SubmitButton';

import SwiperCore, { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import bg1 from 'assets/image/woh_bg_1.jpg';
import bg2 from 'assets/image/woh_bg_2.jpg';
import bg3 from 'assets/image/woh_bg_3.jpg';
import bg4 from 'assets/image/woh_bg_4.jpg';
import bg5 from 'assets/image/woh_bg_5.jpg';
import useWallElements, { UID } from 'hooks/useWallElements/useWallElements';
import { useDispatch } from 'react-redux';
import { addImageToWall } from 'store/actions/wallOfHappiness';
import ActionPrompt from 'components/ActionPrompt/ActionPrompt';
// import useFirebaseAuth from 'state/useFirebase/useFirebase';
// import Cookies from 'js-cookie';

function getOrientation(file, callback) {
	var reader = new FileReader();
	reader.onload = function (e) {

		// //convert target result to array buffer
		// var target = e.target.result;
		// var targetArray = new Uint8Array(target);

		// @ts-expect-error
		var view = new DataView(e.target.result);
		// eslint-disable-next-line
		if (view.getUint16(0, false) != 0xFFD8) {
			return callback(-2);
		}
		var length = view.byteLength, offset = 2;
		while (offset < length) {
			if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
			var marker = view.getUint16(offset, false);
			// eslint-disable-next-line
			offset += 2;
			// eslint-disable-next-line
			if (marker == 0xFFE1) {
				// eslint-disable-next-line
				if (view.getUint32(offset += 2, false) != 0x45786966) {
					return callback(-1);
				}

				// eslint-disable-next-line
				var little = view.getUint16(offset += 6, false) == 0x4949;
				offset += view.getUint32(offset + 4, little);
				var tags = view.getUint16(offset, little);
				offset += 2;
				for (var i = 0; i < tags; i++) {
					// eslint-disable-next-line
					if (view.getUint16(offset + (i * 12), little) == 0x0112) {
						return callback(view.getUint16(offset + (i * 12) + 8, little));
					}
				}
			}
			// eslint-disable-next-line
			else if ((marker & 0xFF00) != 0xFF00) {
				break;
			}
			else {
				offset += view.getUint16(offset, false);
			}
		}
		return callback(-1);
	};
	reader.readAsArrayBuffer(file);
}

const images = [bg1, bg2, bg3, bg4, bg5];

function URLImage({ src, x, y, width, height, cameraUpload }) {
	const imageRef = useRef(null);
	const [image, setImage] = useState(null);

	const handleLoad = () => setImage(imageRef.current);

	useEffect(() => {
		imageRef.current = new window.Image();
		imageRef.current.src = src;
		imageRef.current.addEventListener('load', handleLoad);
	}, [src]);

	useEffect(() => {
		return () => imageRef.current && imageRef.current.removeEventListener('load', handleLoad);
	}, []);

	const imageWidth = image?.width || width;
	const imageHeight = image?.height || height;

	const scaleX = width / imageWidth;
	const scaleY = height / imageHeight;
	const scale = Math.max(scaleX, scaleY);

	// return <Image x={x} y={y} width={width} height={height} image={image} ref={imageRef} />;
	return (
		<Rect
			width={width}
			height={height}
			fillPatternImage={image}
			// fill={'rgba(0,0,0)'}
			fillPatternScaleX={scale}
			fillPatternScaleY={scale}
			rotation={cameraUpload ? 90 : 0}
			offsetY={cameraUpload ? height : 0}
		/>
	);
}

const WallOfHappinessPage = ({ setSubmited }: { setSubmited?: Function }) => {
	const dispatch = useDispatch();
	const [displayErr, setDisplayErr] = useState('');
	// const wallCookie = Cookies.get('cookie-wall-image');

	const [cameraUpload, setCameraUpload] = useState(false);

	let FONT_SIZE = 30;

	const { width: windowWidth } = useWindowSize();

	const [isPen, setIsPen] = useState<boolean>(true);
	const [color, setColor] = useState<string>(generateRandomHEXColor());
	const [fontSize, setFontSize] = useState<number>(FONT_SIZE);
	const [useOverlay, setUseOverlay] = useState(false);

	const stageRef = useRef<any>();

	SwiperCore.use([Navigation]);

	const {
		elements,
		background,
		addLine,
		updateLine,
		addText,
		changeTextProperty,
		updateText,
		changeBackground,
		goBack,
		clear,
		hasText,
	} = useWallElements();

	const isDrawing = useRef(false);

	const handleMouseDown = (e) => {
		isDrawing.current = true;
		const pos = e.target.getStage().getPointerPosition();

		addLine(isPen, color, pos);
	};

	const handleMouseMove = (e) => {
		if (!isDrawing.current) return;
		e.cancelBubble = true;

		const stage = e.target.getStage();
		const point = stage.getPointerPosition();

		updateLine(point);
	};

	const handleMouseUp = () => {
		isDrawing.current = false;
	};

	const handleTextTap = (id) => {
		changeTextProperty(id);
		const input = document.getElementById(`text-input`);
		if (input) {
			input.focus();
		}
	};

	const handleImageClick = (e, img) => {
		changeBackground(img);
		setUseOverlay(false);
	};

	const handleExport = () => {
		if (elements.find((el) => el.text === 'Tap to write your message')) {
			setDisplayErr('You must edit default text to submit');
		} else {
			const uri = stageRef.current.toDataURL();
			dispatch(
				addImageToWall(uri, UID(), () => {
					if (setSubmited) {
						setSubmited();
					}
				})
			);
		}
	};

	return (
		<div className="w-full md:w-60 justify-center items-center px-2 sm:pt-16 pb-24 flex flex-col h-auto">
			{/* <h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">Create Tool</h2> */}
			<div className="mt-3">
				<div className="mx-auto relative">
					<div className="flex justify-center">
						{elements
							.filter((el) => el.type === 'edit-text')
							.map((element, index) => (
								<form
									key={`${element.type}_${index}`}
									className="absolute inset-0 flex items-center justify-center z-10"
									onSubmit={(e) => {
										const value = e.target[`element-${index}`].value;
										updateText(element.id, value ? value : 'Tap to write your message');
									}}
								>
									<input
										id="text-input"
										type="text"
										name={`element-${index}`}
										defaultValue={element.text !== 'Tap to write your message' ? element.text : ''}
										onBlur={(e) => {
											const value = e.target.value;
											if (value.length >= 40) {
												// FONT_SIZE = 10;
												setFontSize(20);
											}
											if (value.length >= 70) {
												setFontSize(15);
											}
											updateText(element.id, value ? value : 'Tap to write your message');
										}}
									/>
								</form>
							))}

						<Stage
							className="mx-auto relative"
							width={windowWidth * 0.6}
							height={windowWidth * 0.6}
							onMouseDown={handleMouseDown}
							onMousemove={handleMouseMove}
							onMouseup={handleMouseUp}
							onTouchStart={handleMouseDown}
							onTouchEnd={handleMouseUp}
							onTouchMove={(e) => handleMouseMove(e)}
							ref={stageRef}
						>
							<Layer>
								<URLImage cameraUpload={cameraUpload} x={0} y={0} width={windowWidth * 0.6} height={windowWidth * 0.6} src={background} />
								{useOverlay && (
									<Rect fill="rgba(0,0,0,0.25)" x={0} y={0} width={windowWidth * 0.6} height={windowWidth * 0.6} />
								)}
							</Layer>
							<Layer>
								{elements
									.filter((el) => el.type === 'line')
									.map((element, index) => (
										<Line
											key={`${element.type}_${index}`}
											points={element.points}
											stroke={element.color}
											strokeWidth={5}
											tension={0.5}
											lineCap="round"
											globalCompositeOperation={element.isPen ? 'source-over' : 'destination-out'}
										/>
									))}
							</Layer>
							<Layer height={windowWidth * 0.7}>
								{elements
									.filter((el) => el.type === 'text')
									.map((element, index) => (
										<Text
											key={`${element.type}_${index}`}
											y={(windowWidth * 0.6) / 2 - fontSize / 2}
											text={element.text}
											fontSize={fontSize}
											draggable
											width={windowWidth * 0.6}
											align="center"
											verticalAlign="middle"
											fill="white"
											fontStyle="bold"
											onTap={() => handleTextTap(element.id)}
										/>
									))}
							</Layer>
						</Stage>
					</div>
				</div>

				<div
					className="wall-swiper-holder w-84 px-6 mx-auto"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Swiper spaceBetween={5} slidesPerView={5} navigation loop className="mt-10 w-100 wall-swiper">
						<SwiperSlide style={{ width: 'auto' }}>
							<div
								className={'h-14 w-100 bg-no-repeat bg-cover bg-center bg-gray-200 flex items-center justify-center'}
							// style={{ backgroundImage: `url(${img})` }}
							>
								<input
									type="file"
									id="bgimage"
									accept="image/*"
									className="hidden"
									onChange={(e) => {
										e.stopPropagation();
										const file = e.target.files[0];
										const reader = new FileReader();
										reader.onloadend = function () {

											// let orientationVal = -1;

											getOrientation(file, function (orientation) {
												// alert('orientation: ' + orientation);
												if (orientation !== -1) {
													setCameraUpload(true);
												}
												// orientationVal = orientation;
											});

											changeBackground(reader.result);
										};
										if (file) {
											reader?.readAsDataURL(file);
											setUseOverlay(true);
										}
										// EXIF.getData(file, function () {
										// 	var orientation = img.exifdata.Orientation										   											
										//   })
									}}
								/>
								<label className="text-center" htmlFor="bgimage">
									{/* <img src={uploadSVG} alt="Upload" className="w-9" /> */}
									Upload Selfie
								</label>
							</div>
						</SwiperSlide>
						{images.map((img, index) => (
							<SwiperSlide
								key={index}
								style={{ width: 'auto' }}
								onClick={(e) => {
									e.stopPropagation();
									handleImageClick(e, img);
								}}
							>
								<div
									className={
										'h-14 w-100 bg-no-repeat bg-cover bg-center' +
										(img === background ? ' border-4 border-blue-500' : '')
									}
									style={{ backgroundImage: `url(${img})` }}
								></div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>

				<div className="flex justify-around mt-5 w-64 mx-auto">
					<div
						className="w-10 h-10 rounded-full bg-darkBlue text-white flex items-center justify-center"
						onClick={() => setIsPen((prevPen) => !prevPen)}
					>
						{isPen ? (
							<img src={pencilSVG} alt="Pencil" className="w-7" />
						) : (
							<img src={eraserSVG} alt="Eraser" className="w-7" />
						)}
					</div>
					<div
						className="w-10 h-10 rounded-full bg-darkBlue flex items-center justify-center"
						onClick={(e) => {
							e.stopPropagation();
							setIsPen(true);
							setColor(generateRandomHEXColor());
						}}
					>
						<div className="w-8 h-8 rounded-full" style={{ backgroundColor: color }}></div>
					</div>
					<div
						className={
							'w-10 h-10 rounded-full bg-darkBlue flex items-center justify-center' + (hasText() ? ' opacity-70' : '')
						}
						onClick={(e) => {
							e.stopPropagation();
							!hasText() && addText();
						}}
					>
						<img src={textSVG} alt="Text" className="w-7" />
					</div>
					<div
						className="w-10 h-10 rounded-full bg-darkBlue flex items-center justify-center"
						onClick={(e) => {
							e.stopPropagation();
							goBack();
						}}
					>
						<img src={undoSVG} alt="Text" className="w-6" />
					</div>
					<div
						className="w-10 h-10 rounded-full bg-darkBlue flex items-center justify-center"
						onClick={(e) => {
							e.stopPropagation();
							clear();
						}}
					>
						<img src={trashSVG} alt="Text" className="w-6" />
					</div>
				</div>
				<div className="flex justify-around mt-8 w-64 mx-auto">
					<SubmitButton
						text="Submit"
						onClick={(e) => {
							e.stopPropagation();
							handleExport();
						}}
						// disabled={wallCookie ? true : false}
						disabled={false}
					/>
				</div>
			</div>
			{displayErr && <ActionPrompt text={displayErr} handleClose={() => setDisplayErr(null)} zIndex={3} />}
		</div>
	);
};

export default WallOfHappinessPage;
