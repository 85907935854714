import bg1 from 'assets/image/woh_bg_1.jpg';
import { useState } from 'react';

export function UID() {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export default function useWallElements() {
	const [background, setBackground] = useState(bg1);
	const [elements, setElements] = useState<any>([
		{ id: 'first-text', type: 'text', text: 'Tap to write your message' },
	]);
	const [previousSteps, setPreviousSteps] = useState([]);

	// Functions

	const clear = () => {
		setElements([]);

		let updatedPreviousSteps = [...previousSteps];
		updatedPreviousSteps = updatedPreviousSteps.filter((step) => step.type === 'change-background');
		setPreviousSteps(updatedPreviousSteps);
	};

	const hasText = () => {
		return elements.find((el) => el.type === 'text' || el.type === 'edit-text');
	};

	const goBack = () => {
		if (previousSteps.length) {
			const lastPreviousStep = previousSteps[previousSteps.length - 1];

			let updatedElements = [...elements];

			switch (lastPreviousStep.type) {
				case 'add-line':
				case 'add-text':
					updatedElements = updatedElements.filter((el) => el.id !== lastPreviousStep.id);
					setElements(updatedElements);
					break;
				case 'update-text':
					const editedElement = [...elements].find((el) => el.id === lastPreviousStep.id);
					editedElement.text = lastPreviousStep.text;

					updatedElements.map((el) => {
						if (el.id === lastPreviousStep.id) {
							return editedElement;
						}
						return el;
					});
					setElements(updatedElements);
					break;
				case 'change-background':
					setBackground(lastPreviousStep.background);
					break;
				default:
					break;
			}

			const editedPreviousSteps = [...previousSteps];
			editedPreviousSteps.pop();

			setPreviousSteps(editedPreviousSteps);
		}
	};

	const savePreviousStep = (step) => {
		setPreviousSteps((prevState) => [...prevState, step]);
	};

	const addToElements = (element) => {
		const previousElements = [...elements];
		setElements([...previousElements, element]);
	};

	const addLine = (isPen, color, pos) => {
		const id = UID();

		savePreviousStep({
			id,
			type: 'add-line',
		});

		addToElements({
			id,
			type: 'line',
			isPen,
			color,
			points: [pos.x, pos.y],
		});
	};

	const updateLine = (point) => {
		const updatedElements = [...elements];

		const lastElement = updatedElements[updatedElements.length - 1];
		if (lastElement.type !== 'line') return;
		lastElement.points = lastElement.points.concat([point.x, point.y]);

		updatedElements.splice(updatedElements.length - 1, 1, lastElement);
		setElements(updatedElements);
	};

	const addText = () => {
		const id = UID();

		savePreviousStep({
			id,
			type: 'add-text',
		});

		addToElements({
			id,
			type: 'text',
			text: 'Tap to write your message',
		});
	};

	const changeTextProperty = (id) => {
		const previousElements = [...elements];
		const editedElement = [...elements].find((el) => el.id === id);
		editedElement.type = 'edit-text';

		previousElements.map((el) => {
			// if (el.id === 'first-text' && el.text === 'Tap to write your message') {
			// 	editedElement.text = '';
			// 	return editedElement;
			// }
			if (el.id === id) {
				return editedElement;
			}
			return el;
		});
		setElements(previousElements);
	};

	const updateText = (id, text) => {
		const previousElements = [...elements];
		const editedElement = [...elements].find((el) => el.id === id);

		savePreviousStep({
			id: editedElement.id,
			type: 'update-text',
			text: editedElement.text,
		});

		editedElement.type = 'text';
		editedElement.text = text;

		previousElements.map((el) => {
			if (el.id === id) {
				return editedElement;
			}
			return el;
		});
		setElements(previousElements);
	};

	const changeBackground = (image) => {
		savePreviousStep({
			type: 'change-background',
			background: background,
		});

		setBackground(image);
	};

	return {
		elements,
		background,
		addLine,
		updateLine,
		addText,
		changeTextProperty,
		updateText,
		changeBackground,
		goBack,
		clear,
		hasText,
	};
}
