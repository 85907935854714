import * as actionTypes from '../actions/actionTypes';

const initialState = {
	wallElements: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_WALL_IMAGES:
			return {
				...state,
				wallElements: action.payload,
			};
		case actionTypes.UPDATE_WALL_ELEMENT:
			const newElement = action.payload;
			const updatedElemenets = state.wallElements.map((el) => {
				if (el.uid === newElement.uid) {
					return newElement;
				}
				return el;
			});
			return {
				...state,
				wallElements: updatedElemenets,
			};
		default:
			return state;
	}
};

export default reducer;
