import * as actionTypes from '../actions/actionTypes';

const initialState = {
	loading: false,
	text: '',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_LOADING:
			return {
				...state,
				loading: action.loading,
				text: action.text,
			};
		default:
			return state;
	}
};

export default reducer;
