import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

export default function LearningPath({ children }: { children: React.ReactChild }) {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const history = useHistory();

	if (!learningPaths) {
		history.push('/');
		return null;
	}

	return <>{children}</>;
}
