import React from 'react';
import desktopWarning from '../../assets/image/desktop-warning.png';

const DesktopWarning = () => {
	return (
		<div
			className="fixed hidden lg:flex m-auto inset-0 bg-gray-200 justify-center items-center"
			style={{ zIndex: '9998' }}
		>
			<div className="bg-white p-12 rounded-2xl w-1/2">
				<img src={desktopWarning} alt="Desktop warning" />
				<p className="text-center text-gray-500 text-3xl mt-8">Please visit toyotatouch.co.za from your phone</p>
				<p className="text-sm text-gray-400 text-center mt-8">
					Need help? Email{' '}
					<a href="mailto:support@toyotatouch.co.za" className="underline">
						support@toyotatouch.co.za
					</a>
				</p>
			</div>
		</div>
	);
};

export default DesktopWarning;
