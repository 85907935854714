// import { Link } from 'react-router-dom';

export default function SubmitButton({
	text = 'Submit',
	onClick,
	disabled,
}: {
	text?: string;
	onClick: any;
	disabled: boolean;
}) {
	return (
		<button
			className={`font-extrabold bg-gradient-to-r from-purple to-darkBlue rounded-full text-white text-xl py-4 px-12 text-center flex items-center ${
				disabled ? 'opacity-60 pointer-events-none' : ''
			}`}
			onClick={onClick}
		>
			{text}
		</button>
	);
}
