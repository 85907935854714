import React, { useState } from 'react';
import playWhite from 'assets/SVG/player/play-white.svg';

import SingleVoiceNote from 'components/SingleVoiceNote/SingleVoiceNote';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getLearningPathData } from 'helpers/utils';
// import ValuesVoiceNotesHelper from 'components/ValuesVoiceNotesHelper/ValuesVoiceNotesHelper';

// const voiceNotesData = [
// 	{
// 		title: 'The Power of THANK YOU',
// 		artist: 'Being Thankful',
// 		url: 'https://www.thesoundarchive.com/ringtones/Jingle-Bells-Ella.mp3',
// 		image: largeAlbumSvg,
// 	},
// 	{
// 		title: 'Voice Note Name',
// 		artist: 'Voice Note Description',
// 		url: 'https://www.thesoundarchive.com/ringtones/Jingle-Bells-Ella.mp3',
// 		image: bg2,
// 	},
// 	{
// 		title: 'Voice Note Name',
// 		artist: 'Voice Note Description',
// 		url: 'https://www.thesoundarchive.com/ringtones/Jingle-Bells-Ella.mp3',
// 		image: bg3,
// 	},
// 	{
// 		title: 'Voice Note Name',
// 		artist: 'Voice Note Description',
// 		url: 'https://www.thesoundarchive.com/ringtones/Jingle-Bells-Ella.mp3',
// 		image: bg4,
// 	},
// 	{
// 		title: 'Voice Note Name',
// 		artist: 'Voice Note Description',
// 		url: 'https://www.thesoundarchive.com/ringtones/Jingle-Bells-Ella.mp3',
// 		image: bg5,
// 	},
// ];

export default function VoiceNotes() {
	const [showMore, setShowMore] = useState<boolean>(false);
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths) as any;
	let dataArray = [];

	if (learningPaths && learningPaths.length) {
		learningPaths.forEach((single) => {
			dataArray.push(getLearningPathData(learningPaths, single.id, 'values-voice-note') as any);
		});
	}

	const voiceNotesData = [];

	if (dataArray.length) {
		dataArray.forEach((data) => {
			voiceNotesData.push({
				title: data.audio_title,
				artist: data.artist,
				url: data.audio.url,
				image: data.artwork.url,
			});
		});
	}

	const [selected, setSelected] = useState(null);

	return (
		<div className="px-8 max-w-sm mx-auto pb-10">
			{/* Removed helper for now  */}
			{/* <ValuesVoiceNotesHelper data={voiceNotesData && voiceNotesData.length ? voiceNotesData[0] : null} /> */}
			{selected && <SingleVoiceNote data={selected} close={() => setSelected(null)} />}
			<h1 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase mb-8">
				VALUES VOICE NOTES
			</h1>
			<div className="mb-20">
				{voiceNotesData.length &&
					voiceNotesData.slice(0, !showMore ? 4 : voiceNotesData.length).map((item, index) => (
						<div key={'voiceNotes_' + index} className="flex items-center mb-4" onClick={() => setSelected(item)}>
							<div className="w-16 h-16 mr-3 relative">
								<img src={item.image} alt={item.title} className="w-full h-full object-cover" />
								<img
									src={playWhite}
									alt="Play"
									className="absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 w-5"
								/>
							</div>
							<div>
								<div className="font-bold text-xl" style={{ lineHeight: 0.9 }}>
									{item.title}
								</div>
								<div className="text-sm text-gray-500">{item.artist}</div>
							</div>
						</div>
					))}
				{!showMore && voiceNotesData?.length > 4 && (
					<h3 className="uppercase text-darkBlue text-md my-5 font-bold py-2" onClick={() => setShowMore(true)}>
						show more
					</h3>
				)}
			</div>
		</div>
	);
}
