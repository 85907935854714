import React from 'react';

export const useRedirectUserToPhase2 = (user) => {
	React.useEffect(() => {
		if (user && user.phase2) {
			// window.location.href = `https://toyotawovencity.web.app/?id=${user.idNumber}`;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(user)]);
};
