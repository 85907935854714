import React, { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getCurrentStep, getLearningPathData, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';

export default function Teaser() {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const [notFinished, setNotFinished] = useState(false);

	const { setStep, addPoint } = useFirebaseAuth();
	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'teaser') as any;

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	return (
		<>
			<LearningPath>
				<ReactFullpage
					licenseKey={'YOUR_KEY_HERE'}
					scrollingSpeed={500} /* Options here */
					verticalCentered={false}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
										{/* <h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">
											Thank you
										</h2> */}
										<div className="">
											<img className="w-8/12 mx-auto" src={learningPaths ? data.featured_image.url : ''} alt="" />
										</div>

										<p className="mt-2 text-center sm:text-lg">{learningPaths ? data.introduction : ''}</p>

										<SwipeUpButton />
									</div>
								</FullPageSection>

								<FullPageSection
									linkTo={`/`}
									swipeCallback={async () => {
										if (learningPaths) {
											localStorage.setItem('step', getCurrentStep(learningPaths, data)?.index + 2);
											setStep(getCurrentStep(learningPaths, data)?.index + 2);
											if (notFinished) {
												await addPoint();
											}
										}
									}}
								>
									<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
										<h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">
											next values to be added
										</h2>
										<ReactMarkdown className="sm:text-lg">
											{learningPaths ? data.next_values_content : ''}
										</ReactMarkdown>
										<FinishModuleButton />
									</div>
								</FullPageSection>

								{/* end of teaser pages */}
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</LearningPath>
		</>
	);
}
