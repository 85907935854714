import { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ModuleButton from 'components/ModuleButton/ModuleButton';
import backgroundImageLarge from 'assets/image/map-bg.png';
// import backgroundImage from 'assets/image/background-image-dashboard.png';
import MapToggle from 'components/MapToggle/MapToggle';
import { largeCoordinates, smallCoordinates } from './coordinates';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getCurrentStep } from 'helpers/utils';
// import HomeOverlayHelper from 'components/HomeOverlayHelper/HomeOverlayHelper';
import FinishOverlay from 'components/FinishOverlay/FinishOverlay';
import flags from 'assets/SVG/flags.png';
// import useStepBg from '../../../hooks/useStepBg/useStepBg';
import dayjs from 'dayjs';
import CompleteOverlay from 'components/CompleteOverlay/CompleteOverlay';
import { useAppState } from 'state';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { useRedirectUserToPhase2 } from 'hooks/useRedirectUserToPhase2/useRedirectUserToPhase2';
// import { activeWeek } from 'state/useFirebase/useFirebase';
// import HomeOverlayHelper from 'components/HomeOverlayHelper/HomeOverlayHelper';

const mapReducer = (state, action) => {
	switch (action.type) {
		case 'zoomOut': {
			return { coords: largeCoordinates, moduleSize: 8, mapSize: 'large' };
		}
		case 'zoomIn': {
			return { coords: smallCoordinates, moduleSize: 20, mapSize: 'small' };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

const dateToWords = (date) => {
	const day = dayjs(date).format('D');
	const month = dayjs(date).format('MMMM');
	const year = dayjs(date).format('YYYY');
	return `${day} ${month} ${year}`;
};

const Home = () => {
	const modal: any = useSelector<RootState>((state) => state.modal);
	const [active, setActive] = useState<number>(
		localStorage.getItem('step') ? parseInt(localStorage.getItem('step')) : 0
	);
	const [showHelper, setShowHelper] = useState(!localStorage.getItem('step') || localStorage.getItem('step') === '0');
	const [finishOverlay, setFinishOverlay] = useState<boolean>(false);
	const [canProgress, setCanProgress] = useState<boolean>(false);
	// const [completeOverlay, setCompleteOverlay] = useState<boolean>(false);
	const user = useSelector<RootState>((state) => state.user) as any;
	const [height, setHeight] = useState(window.innerHeight);

	const { showCompleteModal, setShowCompleteModal, activeLevel } = useAppState();

	useRedirectUserToPhase2(user);

	// const { activeWeek } = useFirebaseAuth();

	const history = useHistory();

	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths) as any;

	const [state, dispatch] = useReducer(mapReducer, {
		coords: smallCoordinates,
		moduleSize: 20,
		mapSize: 'small',
	});

	// let stepBg = useStepBg(active, activeLevel);

	const { moveToNextLevel } = useFirebaseAuth();

	// const [bg, setBg] = useState(backgroundImage);

	const handleGoClick = async () => {
		if (canProgress) {
			if (user.activeLevel === 29) {
				if (dayjs().isAfter(dayjs('2022-11-13 23:59'))) {
					window.location.href = `https://toyotawovencity.web.app/?id=${user.idNumber}`;
					return;
				} else {
					return;
				}
			} else {
				setCanProgress(false);
				await moveToNextLevel();
				setActive(0);
				window.location.reload();
				return;
			}
		}

		if (active !== 0 && !getCurrentStep(learningPaths, learningPaths[activeLevel].steps[active - 1])?.stepPath) return;

		setShowHelper(false);
		history.push(
			`/learning-path/${learningPaths[activeLevel].id}/${
				active === 0
					? getCurrentStep(learningPaths, learningPaths[activeLevel].steps[0])?.stepPath
					: getCurrentStep(learningPaths, learningPaths[activeLevel].steps[active - 1])?.stepPath
			}`
		);
	};

	useEffect(() => {
		if (active === 0 || !active) {
			setActive(user?.weeks?.[`week${activeLevel + 1}`]?.step || 0);
		}

		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (learningPaths) {
			console.log('activeLevel', activeLevel);
			// //!PROD
			if (active === 8 && dayjs(learningPaths[activeLevel].start_of_next_path).isBefore(dayjs().add(1, 'day'))) {
				//!STAGING
				// if (active === 8 && dayjs(learningPaths[activeLevel].start_of_next_path).isBefore(dayjs().add(365, 'day'))) {
				setCanProgress(true);
				// setShowCompleteModal(true);
			}
		}
		// eslint-disable-next-line
	}, [active, activeLevel, learningPaths]);

	const { coords, moduleSize, mapSize } = state;

	const handleToggleMap = () => {
		if (mapSize === 'small') {
			dispatch({ type: 'zoomOut' });
			// setBg(backgroundImageLarge);
		} else {
			dispatch({ type: 'zoomIn' });
			// setBg(backgroundImage);
		}
	};

	const lastButtonIndex =
		learningPaths && learningPaths[activeLevel] && learningPaths[activeLevel].steps
			? learningPaths[activeLevel].steps.length + 1
			: 0;

	const getType = (index) => {
		if (index === active) {
			return 'next';
		} else if (index < active) {
			return 'passed';
		}
		return 'disabled';
	};

	const getTypeLarge = (index) => {
		if (index === user.activeLevel) {
			return 'next';
		} else if (index < user.activeLevel) {
			return 'passed';
		}
		return 'disabled';
	};

	useEffect(() => {
		const updateWindowDimensions = () => {
			const newHeight = window.innerHeight;
			setHeight(newHeight);
		};

		window.addEventListener('resize', updateWindowDimensions);

		return () => window.removeEventListener('resize', updateWindowDimensions);
	}, []);

	const LargeMapItems = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

	// const getLargeMapItems = () => {
	// 	let items = [];
	// 	for (let i = 0; i < 32; i++) {
	// 		items.push(
	// 			<ModuleButton
	// 				index={i}
	// 				x={coords[i].x}
	// 				y={coords[i].y}
	// 				size={moduleSize}
	// 				label=""
	// 				isSmall
	// 				// type={getType(index + 1)}
	// 				// TEMP
	// 				type={i === 0 ? 'active' : 'disabled'}
	// 			/>
	// 		);
	// 	}
	// 	console.log(items);
	// 	return items;
	// };

	// useEffect(() => {
	// 	if (active !== parseInt(localStorage.getItem('step'))) {
	// 		setActive(parseInt(localStorage.getItem('step')));
	// 	}
	// }, [activeLevel]);

	return (
		<div className="p-4 pt-12">
			{showHelper && activeLevel === 0 && <div className="bg-black bg-opacity-50 absolute inset-0 z-20"></div>}

			<div
				style={{
					// backgroundImage: `url(${bg})`,
					backgroundImage: `url(${mapSize === 'large' ? backgroundImageLarge : `/bgs/${activeLevel}_${active}.png`})`,
					backgroundSize: '100% 107%',
					// backgroundSize: 'cover',
					height: height * 0.65,
					maxHeight: '500px',
					// height: '110vw',
				}}
				className="m-auto relative rounded-xl w-full bg-cover bg-gray-100 path-bg -mt-16"
			>
				{finishOverlay && <FinishOverlay setDisplay={setFinishOverlay} />}
				{showCompleteModal && !modal && (
					<CompleteOverlay
						canProgress={canProgress}
						setDisplay={setShowCompleteModal}
						nextLevelDate={dateToWords(learningPaths[activeLevel].start_of_next_path)}
					/>
				)}
				{/* {showHelper && (
					<HomeOverlayHelper
					// handlePlay={() => {
					// 	history.push(
					// 		`/learning-path/${learningPaths[0].id}/${
					// 			active === 0
					// 				? getCurrentStep(learningPaths, learningPaths[0].steps[0])?.stepPath
					// 				: getCurrentStep(learningPaths, learningPaths[0].steps[active - 1])?.stepPath
					// 		}`
					// 	);
					// }}
					/>
				)} */}
				{/* <HomeOverlayHelper
					handlePlay={() => {
						history.push(
							`/learning-path/${learningPaths[0].id}/${
								active === 0
									? getCurrentStep(learningPaths, learningPaths[0].steps[0])?.stepPath
									: getCurrentStep(learningPaths, learningPaths[0].steps[active - 1])?.stepPath
							}`
						);
					}}
				/> */}
				<div className="mx-auto relative h-full">
					{mapSize === 'large' ? (
						<>
							{/* {learningPaths?.length > 0 &&
								learningPaths.map(
									(module, index) =>
										index < 31 && (
											<Link to="#" onClick={() => index === 0 && dispatch({ type: 'zoomIn' })} key={index}>
												<ModuleButton
													index={index}
													x={coords[index].x}
													y={coords[index].y}
													size={moduleSize}
													label={module?.title}
													isSmall
													// type={getType(index + 1)}
													// TEMP
													type={index === 0 ? 'active' : 'disabled'}
												/>
											</Link>
										)
								)} */}
							{LargeMapItems.map((item, index) => (
								<ModuleButton
									index={index}
									x={coords[index].x}
									y={coords[index].y}
									size={moduleSize}
									label=""
									isSmall
									// type={getType(index + 1)}
									// TEMP
									type={getTypeLarge(index)}
								/>
							))}
						</>
					) : (
						<>
							{/* {lastButtonIndex && learningPaths.length && (
								<Link
									to={`/learning-path/${learningPaths[0].id}/${
										active === 0
											? getCurrentStep(learningPaths, learningPaths[0].steps[0])?.stepPath
											: getCurrentStep(learningPaths, learningPaths[0].steps[active - 1])?.stepPath
									}`}
								>
									<ModuleButton
										index={0}
										x={coords[0].x}
										y={coords[0].y}
										size={moduleSize}
										label="START"
										type={getType(0)}
									/>
								</Link>
							)} */}
							{learningPaths &&
								learningPaths[activeLevel]?.steps.map((module, index) => (
									<Link
										to={
											getType(index + 1) === 'disabled'
												? '#'
												: `/learning-path/${learningPaths[activeLevel].id}/${module.__component.substr(14)}`
										}
										key={index}
									>
										<ModuleButton
											index={index}
											x={coords[index].x}
											y={coords[index].y}
											size={moduleSize}
											label={
												module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'TEASER'
													? "WHAT'S NEXT"
													: module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'VALUES VOICE NOTES'
													? 'VALUES VOICE NOTE'
													: module.__component.split('.')[1].split('-').join(' ').toUpperCase() === 'NEWS FLASH'
													? 'VALUES VIPS'
													: module.__component.split('.')[1].split('-').join(' ').toUpperCase()
											}
											type={getType(index + 1)}
										/>
									</Link>
								))}
							{lastButtonIndex && (
								<span>
									<ModuleButton
										index={7}
										x={coords[7].x}
										y={coords[7].y}
										size={moduleSize}
										label="TROPHY"
										type={8 === active ? 'active' : 'disabled'}
									/>
								</span>
							)}
						</>
					)}
				</div>
				{/***** Hidden until big map is ready  *****/}
				<MapToggle onClick={handleToggleMap} />
			</div>
			{learningPaths && (
				<div
					style={{ zIndex: '28' }}
					className={`go-btn -mt-16 sm:-mt-16 w-10/12 mx-auto flex items-center relative ${
						Math.ceil((active / 8) * 100) === 100 ? 'complete' : ''
					}`}
					onClick={handleGoClick}
				>
					{showHelper && activeLevel === 0 && (
						<>
							<div className="text-white text-2xl absolute -top-32 w-full text-center left-0 flex items-center flex-col">
								<p className="font-bold">Tap the button to start</p>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-12 w-12 mt-4"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
								</svg>
							</div>
						</>
					)}
					{Math.ceil((active / 8) * 100) === 100 ? (
						<img className="w-16 h-12 my-auto" alt="flags" src={flags} />
					) : (
						<p className="text-4xl font-bold w-1/3">GO!</p>
					)}
					<div className="w-2/3 ml-2">
						{Math.ceil((active / 8) * 100) === 100 ? (
							<div>
								<p>Next level opens:</p>
								<p>{canProgress ? 'NOW' : dateToWords(learningPaths[activeLevel].start_of_next_path)}</p>
							</div>
						) : (
							<p className="mb-1 text-xs">{learningPaths[activeLevel].title}</p>
						)}
						<div className="flex items-center">
							<progress
								className="w-8/12"
								id="file"
								value={active === 0 ? 0 : Math.ceil(((active - 1) / 7) * 100)}
								max="100"
							></progress>
							<p className="text-center ml-2 font-bold">{active === 0 ? 0 : Math.ceil(((active - 1) / 7) * 100)}%</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Home;
