import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getLearningPathData, handleNextStepOpen, handleStepOpen, getCurrentStep } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { useAppState } from 'state';

export default function NewsFlash() {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	// eslint-disable-next-line
	const [videoCompleted, setVideoCompleted] = useState(false);
	const [notFinished, setNotFinished] = useState(false);

	const { setShowCompleteModal } = useAppState();

	const { setStep, addMilestone, addPoint } = useFirebaseAuth();

	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'news-flash') as any;

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	return (
		<>
			<LearningPath>
				<ReactFullpage
					licenseKey={'YOUR_KEY_HERE'}
					scrollingSpeed={500} /* Options here */
					verticalCentered={false}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
										{/* <h2 className="text-2xl font-extrabold py-0 px-4 text-center text-lightGray uppercase">
											News flash
										</h2> */}
										<div className="mt-0">
											<img className="w-8/12 mx-auto mt-4" src={learningPaths ? data.featured_image.url : ''} alt="" />
										</div>

										<p className="mt-4 text-center sm:text-lg">{learningPaths ? data.introduction : ''}</p>

										<SwipeUpButton />
									</div>
								</FullPageSection>

								<FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
										<ReactMarkdown className="sm:text-lg">
											{learningPaths ? data.living_the_values_content : ''}
										</ReactMarkdown>
										<SwipeUpButton />
									</div>
								</FullPageSection>

								{data.quote && (
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
											<div>
												<div className="flex justify-center h-auto -mt-8">
													<img src={learningPaths ? data.icon?.url : ''} alt="" className="w-28 my-4" />
												</div>
												<p className="font-bold text-lightGray text-8xl leading-none -mb-24 -mt-14 italic">"</p>
												<blockquote className=" sm:text-lg font-medium">
													{learningPaths ? data.quote.quote : ''}{' '}
												</blockquote>
												<p className=" flex flex-row-reverse font-bold text-lightGray text-8xl leading-none -mb-24 -mt-14  italic">
													"
												</p>
												<h5 className="capitalize italic text-lg">{learningPaths ? data.quote.author : ''} </h5>
											</div>
											<SwipeUpButton />
										</div>
									</FullPageSection>
								)}

								{data.video && (
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
											<div>
												<h2 className="text-2xl font-extrabold py-0 px-4 text-center text-lightGray uppercase">
													{data.video_header}
												</h2>
												<div className="relative" id="video">
													<video id="videoPlayer" poster={data.video_thumb ? data.video_thumb.url : ''} controls>
														<source src={learningPaths ? data.video.url : ''} type="video/mp4" />
													</video>
												</div>
											</div>
											<SwipeUpButton />
										</div>
									</FullPageSection>
								)}

								{data.rich_text && (
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
											<ReactMarkdown className="sm:text-lg">{learningPaths ? data.rich_text : ''}</ReactMarkdown>
											<SwipeUpButton />
										</div>
									</FullPageSection>
								)}

								{/* <FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
										
										<div className="mt-0">
											<img
												className="w-8/12 mx-auto mt-4"
												src={learningPaths ? data.teaser_featured_image.url : ''}
												alt=""
											/>
										</div>

										<p className="mt-4 text-center sm:text-lg">{learningPaths ? data.teaser_introduction : ''}</p>

										<SwipeUpButton />
									</div>
								</FullPageSection> */}

								<FullPageSection
									linkTo={`/`}
									swipeCallback={async () => {
										if (learningPaths) {
											await localStorage.setItem('step', getCurrentStep(learningPaths, data)?.index + 2);
											await setStep(getCurrentStep(learningPaths, data)?.index + 2);
											await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
											await addMilestone();

											if (notFinished) {
												await addPoint();
											}
											setShowCompleteModal(true);
										}
									}}
								>
									<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
										<h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">
											next value
										</h2>
										<ReactMarkdown className="sm:text-lg">
											{learningPaths ? data.next_values_content : ''}
										</ReactMarkdown>
										<FinishModuleButton />
									</div>
								</FullPageSection>

								{/* end of know news flash pages */}
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</LearningPath>
		</>
	);
}
