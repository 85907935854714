import * as actionTypes from './actionTypes';

export const setAchievement = (
	name: string,
	message: string,
	messageColor: string,
	description: string,
	points: number,
	imageUrl?: string
) => {
	if (localStorage.getItem(`achievement-${name}`)) {
		return {
			type: null,
		};
	}

	localStorage.setItem(`achievement-${name}`, 'true');

	return {
		type: actionTypes.SET_ACHIEVEMENT,
		name,
		message,
		messageColor,
		description,
		points,
		imageUrl,
	};
};
