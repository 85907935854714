import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

import userIconSVG from 'assets/SVG/leaderboard/user-icon.svg';
import wovenCitySVG from 'assets/SVG/loader/wovenCity.svg';
import closeSVG from 'assets/SVG/close.svg';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import { useEffect, useState } from 'react';

export default function UserSidebar({ open, setIsOpen }) {
	const { getLeaderboardPosition } = useFirebaseAuth();
	const user: any = useSelector<RootState>((state) => state.user);
	const [position, setPosition] = useState(null);

	useEffect(() => {
		if (user && open) {
			(async function () {
				setPosition(await getLeaderboardPosition(user.dealerCode));
			})();
		}

		// eslint-disable-next-line
	}, [user, open]);

	return (
		<>
			<div
				className={`fixed right-0 top-0 w-full h-full z-50 bg-black transition-opacity  ${
					open ? 'opacity-50 pointer-events-all' : 'opacity-0 pointer-events-none'
				}`}
				onClick={() => setIsOpen(false)}
			></div>

			<div
				className={`fixed right-0 top-0 w-64 z-50 h-full bg-white transition-transform transform p-4 ${
					open ? '' : 'translate-x-full'
				}`}
			>
				<img className="absolute right-3 top-3 w-7" src={closeSVG} alt="Close" onClick={() => setIsOpen(false)} />
				<div className="w-16 h-16 my-5">
					<img className="w-full h-full rounded-full" src={userIconSVG} alt="" />
				</div>

				<div className="text-md uppercase font-black">{user.fullname}</div>
				{/* <div className="text-md uppercase mb-5">{user.idNumber}</div> */}

				{/* <div className="text-lg font-thin text-lightGray mb-2">@Thato</div> */}
				<div className="grid grid-cols-6 gap-y-1 mb-5">
					{user.achievements?.map((achievement, index) => {
						if (achievement.includes('login')) {
							return <img className="w-6" src={`/achievements/login/${achievement}.svg`} alt="Login achievement" />;
						}
						if (achievement.includes('milestone')) {
							return (
								<img className="w-6" src={`/achievements/milestone/${achievement}.svg`} alt="Milestone achievement" />
							);
						}
						if (achievement.includes('quiz')) {
							return <img className="w-6" src={`/achievements/quiz/${achievement}.svg`} alt="Quiz achievement" />;
						}
						return <img className="w-6" src={`/achievements/${achievement}.svg`} alt="Likes achievement" />;
					})}
					{/* <img className="w-5 h-5 ml-1 rounded-full border-gray-200" alt="" src={toyotaTrophy2SVG} /> */}
				</div>

				<div className="text-md font-bold">Points</div>
				<div className="text-md font-thin text-lightGray mb-5">{user?.points || 0}</div>

				<div className="text-md font-bold">Leaderboard</div>
				<div className="text-md font-thin text-lightGray">#{position}</div>

				<img className="absolute right-6 bottom-6 w-16" src={wovenCitySVG} alt="Woven City" />
			</div>
		</>
	);
}
