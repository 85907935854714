import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkIfPhotoIsLiked, getLearningPathData, handleNextStepOpen } from 'helpers/utils';
import LearningPath from 'hoc/LearningPath/LearningPath';
import heartIcon from 'assets/SVG/wallOfHappiness/heart-icon.svg';
// import heartIconOutline from 'assets/SVG/wallOfHappiness/heart-icon-outline.svg';
import heartIconOutlineRed from 'assets/SVG/wallOfHappiness/heart-icon-outline-red.svg';
import closeSVG from 'assets/SVG/wallOfHappiness/close.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
import { RootState } from 'store/reducers';
import { dislikeImage, getWallImages, likeImage } from 'store/actions/wallOfHappiness';
// import WallOfHappinessHelper from 'components/WallOfHappinessHelper/WallOfHappinessHelper';
import ActionPrompt from 'components/ActionPrompt/ActionPrompt';
import useFirebaseAuth from 'state/useFirebase/useFirebase';

export default function WallOfHappiness() {
	const dispatch = useDispatch();
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	// const [displayNextBtn, setDisplayNextBtn] = useState(localStorage.getItem('liked-photos') ? true : false);
	const history = useHistory();

	let { id } = useParams();
	const { addAchievement, setStep, addLikePoint } = useFirebaseAuth();

	const images = useSelector<RootState>((state) => state?.wall?.wallElements) as any;
	const [openedItem, setOpenedItem] = useState(null);
	const [promptVisible, setPromptVisible] = useState(false);

	useEffect(() => {
		dispatch(getWallImages());
		//eslint-disable-next-line
	}, []);

	// eslint-disable-next-line
	let data;

	if (id) {
		data = getLearningPathData(learningPaths, id, 'wall-of-happiness');
	}

	const handleLikeAndDislike = (el) => {
		if (checkIfPhotoIsLiked(el.uid)) {
			dispatch(
				dislikeImage(el.uid, (newElem) => {
					openedItem && setOpenedItem(newElem);
				})
			);
		} else {
			dispatch(
				likeImage(
					el.uid,
					(newElem) => {
						openedItem && setOpenedItem(newElem);
						if (data) {
							setOpenedItem(null);
							handleNextStepOpen(learningPaths, data, (val) => setStep(val));
							history.push(`/`);
						}
					},
					likeAchievementCallback
				)
			);
			addLikePoint();
		}
	};

	const likeAchievementCallback = (achievement: any, points: number) => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			const achievements = user.achievements as any;
			if (achievements) {
				if (achievements.some((el) => el === achievement)) {
					return false;
				} else {
					addAchievement(achievement, points);
					return true;
				}
			}
		}
	};

	return (
		<>
			<LearningPath>
				<div
					className="h-screen"
					// className="h-screen -mt-16 pb-20"
				>
					{/* <WallOfHappinessHelper /> */}
					{openedItem && (
						<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-10 pt-20">
							<motion.div
								transition={{ duration: 0.2 }}
								initial={{ scale: 0.33, opacity: 0 }}
								animate={{ opacity: 1, scale: 1 }}
							>
								<img src={openedItem.image} alt="opened" className="w-full" />
							</motion.div>
							<span
								onClick={() => handleLikeAndDislike(openedItem)}
								className="flex items-center justify-between px-2.5 w-14 h-7 mr-2 bg-white rounded-full absolute left-2 top-24 shadow-2xl"
							>
								{/* HERE  */}
								<img
									src={checkIfPhotoIsLiked(openedItem.uid) ? heartIcon : heartIconOutlineRed}
									alt="likes"
									className="h-4 mr-1"
								/>
								<span className="text-lg">{openedItem.likes ? openedItem.likes : '0'}</span>
							</span>
							<div className="flex justify-around mt-5 w-28 mx-auto absolute top-0 right-0">
								<div
									className="w-10 h-10 rounded-full bg-darkBlue flex items-center justify-center"
									onClick={() => setOpenedItem(null)}
								>
									<img src={closeSVG} alt="Text" className="w-5" />
								</div>
								{/* <div
									className="w-10 h-10 rounded-full bg-darkBlue flex items-center justify-center"
									onClick={() => handleLikeAndDislike(openedItem)}
								>
									<img
										src={checkIfPhotoIsLiked(openedItem.uid) ? heartIcon : heartIconOutline}
										alt="Text"
										className="w-6"
									/>
								</div> */}
							</div>
						</div>
					)}
					<Swiper
						direction="vertical"
						slidesPerView="auto"
						slidesPerColumn={3}
						slidesPerColumnFill="row"
						className="w-100 h-full wall-swiper"
					>
						{images.map((object, index) => (
							<SwiperSlide key={index} onClick={(e) => setOpenedItem(object)} style={{ height: 'auto', width: 'auto' }}>
								<div
									className="h-32 w-100 bg-no-repeat bg-cover bg-center relative"
									style={{ backgroundImage: `url(${object.image})`, width: '100%' }}
								>
									<span
										onClick={(e) => {
											e.stopPropagation();
											handleLikeAndDislike(object);
										}}
										className="flex items-center justify-between px-1.5 w-10 h-5 mr-2 bg-white rounded-full absolute left-2 top-2 shadow-2xl"
									>
										<img
											src={checkIfPhotoIsLiked(object.uid) ? heartIcon : heartIconOutlineRed}
											alt="likes"
											className="h-3 mr-1"
										/>
										<span className="text-sm">{object.likes ? object.likes : '0'}</span>
									</span>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</LearningPath>

			{promptVisible && (
				<ActionPrompt
					text="Please like a picture
						to continue"
					bottom={0}
					handleClose={() => setPromptVisible(false)}
				/>
			)}
		</>
	);
}
