import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';
import { useAppState } from 'state';
// import { activeWeek } from 'state/useFirebase/useFirebase';

const ValuesView = () => {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const [answer, setAnswer] = useState(null);
	const [notFinished, setNotFinished] = useState(false);
	const [showQuiz, setShowQuiz] = useState(true);

	const { activeLevel } = useAppState();

	let { id } = useParams();

	const { setStep, completeValuesViewQuiz, addPoint } = useFirebaseAuth();

	const data = getLearningPathData(learningPaths, id, 'values-view') as any;

	useEffect(() => {
		setShowQuiz(!JSON.parse(localStorage.getItem('user'))?.weeks?.[`week${activeLevel + 1}`]?.valuesViewQuizComplete);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		// await completeValuesViewQuiz();
		if (notFinished) {
			await addPoint();
		}
	};

	// const valuesViewQuizComplete = JSON.parse(localStorage.getItem('user'))?.weeks?.[`week${activeLevel + 1}`]
	// 	?.valuesViewQuizComplete;

	return (
		<>
			<LearningPath>
				<ReactFullpage
					licenseKey={'YOUR_KEY_HERE'}
					scrollingSpeed={500} /* Options here */
					verticalCentered={false}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								{/* know values view page */}
								<FullPageSection>
									<div className="w-full md:w-60 justify-center items-center px-6 py-6 sm:pt-16 flex flex-col h-auto">
										<div className="mt-0">
											<img className="w-8/12 mx-auto mt-4" src={learningPaths ? data.featured_image.url : ''} alt="" />
										</div>
										<p className="mt-12 text-center text-sm sm:text-lg">{learningPaths ? data.introduction : ''}</p>

										<SwipeUpButton />
									</div>
								</FullPageSection>
								<FullPageSection swipeCallback={!showQuiz ? swipeCallback : () => {}} linkTo={!showQuiz ? '/' : null}>
									<div className="w-full md:w-60 h-3/4 justify-center items-center px-6 py-6 flex flex-col">
										<div className="relative" id="video">
											<video id="videoPlayer" poster={data.video_thumb.url} controls>
												<source src={learningPaths ? data.video.url : ''} type="video/mp4" />
											</video>
										</div>
										{!showQuiz ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
									</div>
								</FullPageSection>
								{showQuiz && (
									<FullPageSection
										promptText="Please select an answer to continue"
										linkTo={answer !== null && `/`}
										// swipeCallback={swipeCallback}
										promptActive={answer === null}
									>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question</h2>

											<p className="sm:text-lg">{data?.question?.question_text}</p>
											<KnowledgeQuizForm
												answers={data?.question?.answers}
												isImageQuestion={data?.question?.answers[0].type === 'image'}
												onSelect={async (answer) => {
													setAnswer(answer);
													await completeValuesViewQuiz();
													await swipeCallback();
												}}
											/>
											{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
										</div>
									</FullPageSection>
								)}

								{/* end of values view pages */}
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</LearningPath>
		</>
	);
};

export default ValuesView;
