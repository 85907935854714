import wovenCity from 'assets/SVG/loader/wovenCity.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

export default function Loader() {
	const loaderText = useSelector<RootState>((state) => state.loader.text);
	return (
		<div
			className="fixed w-full h-full top-0 left-0 bg-opacity-90 bg-white flex flex-col justify-center items-center"
			style={{ zIndex: 1000 }}
		>
			<img src={wovenCity} alt="Logo" className="w-56" />
			{loaderText && <h2 className="text-gray-400 font-semibold text-xl mt-5">{loaderText}</h2>}
		</div>
	);
}
