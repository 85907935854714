import ActionPrompt from 'components/ActionPrompt/ActionPrompt';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function FullPageSection({
	children,
	linkTo,
	className = '',
	swipeCallback,
	promptText = '',
	promptActive = false,
	showCloseButton = false,
	closeFunction,
}: {
	children: React.ReactNode;
	linkTo?: string;
	className?: string;
	swipeCallback?: Function;
	promptText?: string;
	promptActive?: boolean;
	showCloseButton?: boolean;
	closeFunction?: any;
}) {
	const [touchStart, setTouchStart] = React.useState(0);
	const [touchEnd, setTouchEnd] = React.useState(0);
	const [swipeTry, setSwipeTry] = React.useState(false);

	const history = useHistory();

	function handleTouchStart(e) {
		setTouchStart(e.targetTouches[0].clientY);
		setTouchEnd(0);
	}

	function handleTouchMove(e) {
		setTouchEnd(e.targetTouches[0].clientY);
	}

	function handleTouchEnd() {
		if (linkTo) {
			if (touchStart - touchEnd > 120 && touchEnd > 0) {
				history.push(linkTo);
				swipeCallback && swipeCallback();
			}
		} else {
			if (touchStart - touchEnd > 120 && touchEnd > 0) {
				setSwipeTry(true);
			}
		}
	}

	return (
		<div
			className={`px-8 section module-content ${className} relative`}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			{showCloseButton && (
				<div className="absolute top-4 right-4" onClick={closeFunction}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-8 w-8 text-gray-700"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</div>
			)}
			{children}
			{promptText && promptActive && swipeTry && (
				<ActionPrompt handleClose={() => setSwipeTry(false)} text={promptText} />
			)}
		</div>
	);
}
