import axios from 'axios';
import * as actionTypes from './actionTypes';
import { setLoading } from './loader';

export function setLearningPaths(payload: any) {
	return {
		type: actionTypes.SET_LEARNING_PATHS,
		payload,
	};
}
export function getLearningPaths() {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			//!staging
			// axios.get(`${process.env.REACT_APP_CMS_URL}/learning-paths?_sort=id:ASC`).then((response) => {
			//!prod
			axios.get(`${process.env.REACT_APP_CMS_URL}/learning-paths?_sort=id:ASC&is_active=true`).then((response) => {
				dispatch(setLearningPaths(response.data.filter((data) => data.title.includes('WEEK'))));
			});
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(setLoading(false));
		}
	};
}
