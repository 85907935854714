import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getCurrentStep, getLearningPathData, handleNextStepOpen, handleStepOpen } from 'helpers/utils';
import FinishModuleButton from 'components/Buttons/FinishModuleButton/FinishModuleButton';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useFirebaseAuth from 'state/useFirebase/useFirebase';
import KnowledgeQuizForm from 'components/KnowledgeQuizForm/KnowledgeQuizForm';
import SelectAnswerButton from 'components/Buttons/SelectAnswerButton/SelectAnswerButton';
import { useAppState } from 'state';
// import { activeWeek } from 'state/useFirebase/useFirebase';

const ValuesVoiceNote = () => {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths);
	const [answer, setAnswer] = useState(null);
	const [notFinished, setNotFinished] = useState(false);
	const [showQuiz, setShowQuiz] = useState(true);

	const { activeLevel } = useAppState();

	let { id } = useParams();

	const data = getLearningPathData(learningPaths, id, 'values-voice-note') as any;
	const { setStep, completeValuesVoiceNoteQuiz, addPoint } = useFirebaseAuth();

	useEffect(() => {
		setShowQuiz(
			!JSON.parse(localStorage.getItem('user'))?.weeks?.[`week${activeLevel + 1}`]?.valuesVoiceNoteQuizComplete
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	// useEffect(() => {
	// 	console.log('Not finished: ', notFinished);
	// }, [notFinished]);

	const swipeCallback = async () => {
		await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
		// await completeValuesVoiceNoteQuiz();
		if (notFinished) {
			await addPoint();
		} else {
			// console.log('Not adding VVN point');
		}
	};

	// const valuesVoiceNoteQuizComplete = JSON.parse(localStorage.getItem('user'))?.weeks?.[`week${activeLevel + 1}`]
	// 	?.valuesVoiceNoteQuizComplete;

	return (
		<>
			<LearningPath>
				{data && (
					<ReactFullpage
						licenseKey={'YOUR_KEY_HERE'}
						scrollingSpeed={500} /* Options here */
						verticalCentered={false}
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									{/* know the basics page */}
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
											<div className="">
												<img className="w-3/4 mx-auto mt-8" src={learningPaths ? data.featured_image.url : ''} alt="" />
											</div>
											<h2 className="py-4 px-4 text-center text-darkGray">{learningPaths ? data.introduction : ''}</h2>
											{/* <p className="mt-12 text-center">
                    {learningPaths ? data.introduction : ""}
                  </p> */}

											<SwipeUpButton />
										</div>
									</FullPageSection>

									<FullPageSection linkTo={!showQuiz ? '/' : null} swipeCallback={!showQuiz ? swipeCallback : () => { }}>
										<div className="w-full md:w-60 justify-center items-center py-6 sm:pt-16 flex flex-col h-auto">
											<AudioPlayer
												onEnded={() => { }}
												image={data.artwork.url}
												artist={data.artist}
												title={data.audio_title}
												url={data.audio.url}
											/>
										</div>

										<div className="w-full md:w-60 justify-center items-center px-2 py-6 flex flex-col h-auto">
											<ReactMarkdown>{learningPaths ? data.example : ''}</ReactMarkdown>
											{!showQuiz ? <FinishModuleButton /> : <SwipeUpButton showIcon />}
										</div>
									</FullPageSection>
									{showQuiz && (
										<FullPageSection
											promptText="Please select an answer to continue"
											linkTo={answer !== null && `/`}
											// swipeCallback={swipeCallback}
											promptActive={answer === null}
										>
											<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
												<h2 className="text-lg sm:text-2xl text-lightGray font-bold">Question</h2>

												<p className="sm:text-lg">{data?.question?.question_text}</p>
												<KnowledgeQuizForm
													answers={data?.question?.answers}
													isImageQuestion={data?.question?.answers[0].type === 'image'}
													onSelect={async (answer) => {
														setAnswer(answer);
														await completeValuesVoiceNoteQuiz();
														await swipeCallback();
													}}
												/>
												{answer !== null ? <FinishModuleButton /> : <SelectAnswerButton />}
											</div>
										</FullPageSection>
									)}

									{/* end of know the basics pages */}
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				)}
			</LearningPath>
		</>
	);
};

export default ValuesVoiceNote;
