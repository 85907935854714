import dayjs from 'dayjs';

export default function FinishOverlay({ setDisplay }) {
	const startDate = dayjs(new Date());
	const endDate = dayjs().endOf('week');

	const minutes = endDate.diff(startDate, 'minutes');
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	const hoursLeft = hours - days * 24;
	const minutesLeft = minutes - (hoursLeft * 60 + days * 24 * 60);
	return (
		<div
			
			className={
				'fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 flex items-center justify-center text-center text-white'
			}
			onClick={() => setDisplay(false)}
		>
			<div>
				<div className="uppercase text-3xl font-bold mb-3">Coming Soon!</div>
				<div className="uppercase font-medium text-lg">
					{days} Day {hoursLeft} Hours {minutesLeft} Minutes
				</div>
			</div>
		</div>
	);
}
