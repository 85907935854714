import { Link } from 'react-router-dom';

export default function FinishModuleButton({ active = true, linkTo = '#' }: { active?: boolean; linkTo?: string }) {
	return (
		<Link
			to={linkTo}
			className={`${
				active ? 'text-toyotaGreen' : 'invisible'
			} font-extrabold text-xl py-4 px-4 text-center absolute bottom-10 flex items-center`}
		>
			<h3 className="mr-2 text-base">Swipe up to Finish</h3>
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
		</Link>
	);
}
