import rightHeaderSVG from 'assets/SVG/header/header-right.svg';
// import leftHeaderSVG from 'assets/SVG/header/header-left-bg.svg';
// import logo from 'assets/SVG/header/logo.svg';
import logo from 'assets/image/color_logo.png';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

const hideHeaderURLS = ['about', 'values-voicenotes', 'wall-of-happiness', 'learning-path'];

export default function Header({
	setIsSidebarOpen,
	zIndex,
}: {
	setIsSidebarOpen?: (boolean) => void;
	zIndex?: Number;
}) {
	const location = useLocation();
	const user: any = useSelector<RootState>((state) => state.user);

	let hideHeader = false;

	hideHeaderURLS.map((url) => {
		if (location.pathname.includes(url)) {
			hideHeader = true;
		}
		return true;
	});

	return (
		<>
			{location.pathname.includes('learning-path') && !location.pathname.includes('knowledge-quiz') && (
				<Link to="/" className="absolute top-4 left-4 z-50">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
					</svg>
				</Link>
			)}

			{!hideHeader && window.location.pathname !== '/leaderboard' && (
				<div id="header">
					<div className="pt-16 pointer-events-none"></div>
					<div
						className="fixed w-full top-0 flex flex-row justify-between align-top z-50"
						style={{ zIndex: String(zIndex) }}
					>
						<div
							className="w-40 flex"
							style={{
								backgroundImage: `url(${logo})`,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								filter: 'drop-shadow(2px 2px 3px rgba(0,0,0,0.2))',
								marginTop: '1em',
								marginLeft: '0.5em',
								width: '6em',
							}}
						></div>

						<div className="flex items-center mr-2 mt-2" onClick={() => setIsSidebarOpen && setIsSidebarOpen(true)}>
							<div className="text-gray-400 uppercase mr-2.5">
								<span className="font-bold">{user?.points || 0}</span> Points
							</div>
							<div
								className="m-left w-9 h-9 pr-0 flex justify-center"
								style={{
									backgroundImage: `url(${rightHeaderSVG})`,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPositionX: 'right',
									filter: 'drop-shadow(2px 2px 3px rgba(0,0,0,0.2))',
								}}
							></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
