import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import FullPageSection from 'hoc/FullPageSection/FullPageSection';
import { getLearningPathData, handleStepOpen, handleNextStepOpen, getCurrentStep } from 'helpers/utils';
import SwipeUpButton from 'components/Buttons/SwipeUpButton/SwipeUpButton';
import LearningPath from 'hoc/LearningPath/LearningPath';
import { RootState } from 'store/reducers';
import { useSelector } from 'react-redux';
import WallOfHappinessPage from 'containers/pages/WallOfHappiness/WallOfHappiness';

import { useHistory } from 'react-router-dom';
import useFirebaseAuth from 'state/useFirebase/useFirebase';

export default function WallOfHappinessIntro({ history }) {
	const learningPaths = useSelector<RootState>((state) => state.learningPaths.learningPaths) as any;
	const [data, setData] = useState() as any;
	const [submited, setSubmited] = useState(false);
	const [notFinished, setNotFinished] = useState(false);

	const { setStep, addPoint } = useFirebaseAuth();

	const navHistory = useHistory();

	let { id } = useParams();
	useEffect(() => {
		setData(getLearningPathData(learningPaths, id, 'wall-of-happiness'));

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (learningPaths) {
			setNotFinished(parseInt(localStorage.getItem('step')) - 1 === getCurrentStep(learningPaths, data)?.index);
			handleStepOpen(learningPaths, data, (val) => setStep(val));
		}
		// eslint-disable-next-line
	}, [data, learningPaths]);

	return (
		<>
			<LearningPath>
				{data && (
					<ReactFullpage
						licenseKey={'YOUR_KEY_HERE'}
						scrollingSpeed={500} /* Options here */
						verticalCentered={false}
						normalScrollElements=".disabled-scroll"
						render={({ state, fullpageApi }) => {
							return (
								<ReactFullpage.Wrapper>
									{/* know the basics page */}
									<FullPageSection>
										<div className="w-full md:w-60 justify-center items-center px-2 py-6 sm:pt-16 flex flex-col h-auto">
											{/* <h2 className="text-2xl font-extrabold py-4 px-4 text-center text-lightGray uppercase">
												{data.intro}
											</h2> */}
											<div className="mt-0">
												<img
													className="w-8/12 mx-auto mt-4"
													src={data.featured_image?.url}
													alt="Wall Of Happiness Featured"
												/>
											</div>
											<p className="mt-2 text-sm sm:text-lg text-center">{data.introduction}</p>

											<SwipeUpButton
												showIcon
												// hide={true}
											/>
										</div>
									</FullPageSection>
									<FullPageSection
										className="disabled-scroll"
										promptText="Please submit your design
										to continue"
										promptActive={submited ? false : true}
										linkTo={submited && `/`}
									>
										<WallOfHappinessPage
											setSubmited={async () => {
												await handleNextStepOpen(learningPaths, data, (val) => setStep(val));
												setSubmited(true);
												navHistory.push(`/`);
												if (notFinished) {
													await addPoint();
												}

												localStorage.setItem('wall-image-submited', 'true');
											}}
										/>
										{submited && (
											<div className="w-full md:w-60 justify-center items-center px-2 py-6 flex flex-col h-auto">
												<SwipeUpButton
													showIcon
													// hide={true}
												/>
											</div>
										)}
									</FullPageSection>
									{/* end of know the basics pages */}
								</ReactFullpage.Wrapper>
							);
						}}
					/>
				)}
			</LearningPath>
		</>
	);
}
