interface IQuickQuizForm {
	answers: Array<any>;
	isImageQuestion: boolean;
	onSelect: any;
}

const QuickQuizForm = ({ answers = [], isImageQuestion, onSelect }: IQuickQuizForm) => {
	// const [selected, setSelected] = useState(answers[0]);
	return (
		<>
			{isImageQuestion ? (
				<div className="flex flex-col items-center w-full">
					{answers.map((answer, index) => (
						<div
							className="relative flex items-center w-full justify-center"
							onClick={() => {
								onSelect(answer.is_correct_answer);
							}}
							key={index}
						>
							<div className="p-2 absolute left-8 rounded-full bg-gray-300"></div>
							<div className="h-28 w-24 mb-0">
								<img className="w-full h-full object-contain" src={answer.image_option.url} alt="" />
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="flex flex-col items-center w-full">
					{answers.map((answer, index) => (
						<div
							className="relative flex items-center w-full"
							onClick={() => {
								onSelect(answer.is_correct_answer);
							}}
							key={index}
						>
							<div className="p-2 rounded-full bg-gray-300"></div>
							<p className="ml-6 sm:text-base">{answer.answer_text}</p>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default QuickQuizForm;
